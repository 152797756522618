import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useMemo, useState } from "react";
import { MetricsData } from '../../atoms/MetricsTimelineV2';

const useStyles = makeStyles((theme) => ({
    urlIcon: {
        width: '36px',
        height: '36px',
        color: theme.colors.neutral['600'],
    },
    leftBlockDivider: {
        width: '50%',
        height: '100%',
    },
    rightBlockDivider: {
        width: '50%',
        height: '100%',
        borderLeft: '3px solid lightgrey',
        padding: '8px 0px 24px 8px',
    },
    funding: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
    fundingType: {
        color: theme.colors.neutral['600'],
        fontSize: '0.95rem',
        fontWeight: '500',
    },
    moneyRaised: {
        color: '#666666',
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    investorList: {
        color: "#666666",
        fontSize: "0.85rem",
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
    },
    investor: {
        color: theme.palette.primary.main,
        fontSize: '0.85rem',
        fontWeight: 'bold',
    },
    icon: {
        width: 24,
        height: 24,
        padding: 'unset',
        color: theme.colors.neutral['600'],
    },
}));

const FundingComponent: React.FC<{
    item: any,
    isEditable?: boolean,
    hovered?: boolean,
    onEdit?: (customValues?: MetricsData) => void;
    onHover?: () => void;
}> = ({item, isEditable, hovered, onEdit, onHover}) => {
    const classes = useStyles();

    return (
        <Stack direction="column" spacing="2px" alignItems="flex-start" onMouseEnter={() => onHover?.()}>
            <Typography className={classes.fundingType}> {item.funding_type} </Typography>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                <Typography className={classes.moneyRaised}> {item?.money_raised?.replace('US$', '$')} </Typography>
                {hovered && isEditable ? (
                    <IconButton size="small" disableRipple
                        className={classes.icon}
                        onClick={(e) => {
                            e.stopPropagation();
                            onEdit?.({
                                metric: item.funding_type,
                                value: item?.money_raised?.replace('US$', '$'),
                                date: item.funding_date,
                                units: '$',
                            });
                        }}
                    > <EditOutlinedIcon fontSize="small" /> </IconButton>
                ) : (<Box />)}
            </Stack>
            {!!item.investor_list &&
                <Tooltip title={item.investor_list.length > 40 ? item.investor_list: ''} placement="top">
                    <Typography className={classes.investorList}> 
                        <Typography className={classes.investor} component="span" display="inline">
                            {'Investors: '}
                        </Typography>
                        {item.investor_list}
                    </Typography>
                </Tooltip>
            }
        </Stack>
    );
}

const FundingElement: React.FC<{
    items: any[],
    onEdit?: (customValues?: MetricsData) => void;
}> = ({items, onEdit}) => {
    const classes = useStyles();
    const [hoverAnswer, setHoverAnswer] = useState<boolean>(false);

    const priorFunding = useMemo(() => items.filter((item: any) => 
        !['fundraising', 'valuation'].join('|').includes(item.funding_type.toLowerCase())
    ), [items]);

    const fundraisingValuation = useMemo(() => items.filter((item: any) => 
        ['fundraising', 'valuation'].join('|').includes(item.funding_type.toLowerCase())
    ), [items]);
    
    if (!items.length) 
        return (<></>);

    return (<>
        <Stack direction="column" alignItems="center" justifyContent="flex-end" minHeight="200px" width ="100%" height="100%">
            <MonetizationOnIcon className={classes.urlIcon} />
            <Stack direction="row" alignItems="center" justifyContent="flex-start" width="100%" height="100%"
                onMouseLeave={() => setHoverAnswer(false)}>
                <Stack className={classes.leftBlockDivider} />
                <Stack className={classes.rightBlockDivider}>
                    {!!priorFunding.length && (
                        <Typography className={classes.funding}> {'Prior Funding'} </Typography>
                    )}
                    {priorFunding.map((item: any, i) => (
                        <FundingComponent 
                            item={item} 
                            hovered={hoverAnswer} 
                            onEdit={onEdit} 
                            onHover={() => setHoverAnswer(true)}    
                            key={'funding-elements-v2-137-' + i} />
                    ))}
                    {fundraisingValuation.map((item: any, i) => (
                        <FundingComponent 
                            item={item} 
                            isEditable
                            hovered={hoverAnswer} 
                            onEdit={onEdit} 
                            onHover={() => setHoverAnswer(true)}    
                            key={'funding-elements-v2-146-' + i} />
                    ))}
                </Stack>
            </Stack>
        </Stack>
    </>);
}

export default FundingElement;