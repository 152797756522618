import React, { useContext, useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {SubscriptionContext} from "../../../contexts/SubscriptionContext";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

const useStyles = makeStyles((theme) => ({
    currentStatsText: {
        fontSize: '1.25rem',
        fontWeight: '700',
        fontFamily: 'Inter',
    },
    dashboardStatsText: {
        fontSize: '1.2rem',
        fontWeight: '600',
        fontFamily: 'Inter',
    },
    subStatsText: {
        fontSize: '0.9rem',
        fontFamily: 'Inter',
        color: theme.colors.warning[500],
    },
}));

const DashboardCounter: React.FC<{
    flags?: any,
}> = ({ flags }) => {
    const classes = useStyles();
    const { usage } = useContext(SubscriptionContext);
    const [showCounter, setShowCounter] = useState<boolean>(false);

    useEffect(() => {
        setShowCounter(flags?.monthlyDashboardLimit > 0);
    }, [flags, usage]);

    if (!showCounter)
        return (<></>);

    return (<>
        <Stack direction="column" alignItems="flex-start" justifyContent="center" width="450px">
            <Typography className={classes.dashboardStatsText}>
                <Typography component="span" className={classes.currentStatsText} display="inline">
                    {usage?.dashboardsCreated ?? 0}
                </Typography>
                {`/${flags?.monthlyDashboardLimit} dashboards created`}
            </Typography>
            {
                flags?.monthlyDashboardLimit > 0 && flags?.monthlyDashboardLimit < 10 &&
                    <Typography className={classes.subStatsText}>
                        {'Upgrade to our Pro plan for up to 200 dashboards per month'}
                    </Typography>
            }

        </Stack>
    </>);
};

export default withLDConsumer()(DashboardCounter);