import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext } from '../../../contexts/AuthContext';
import { DashboardContext } from "../../../contexts/DashboardContext";
import { DashboardQueriesContext } from '../../../contexts/DashboardQueriesContext';
import { DashboardsContext, OverviewQueryTitles } from '../../../contexts/DashboardsContext';
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import { getDashboardQueriesFunc, refreshWorkspaceScoreFunc } from '../../../lib/helper';
import { scrollbarStyle } from '../../../shared/dashboard';
import { DashboardQuery } from '../../../types/files';
import WorkspacesBulkShareEmail from "../../atoms/workspaces/WorkspacesBulkShareEmail";
import NoWorkspacesFoundView from "../../molecules/workspaces-empty/NoWorkspacesFoundView";
import WorkspacesSearchOrCreate from "../../molecules/workspaces-search-create/WorkspacesSearchOrCreate";
import WorkspacesTableHeader, { FullTableHeaders } from '../../molecules/workspaces-table/WorkspacesTableHeader';
import InvestorsTable from "../../organisms/investors/InvestorsTable";
import { collectionKey as partialKey } from "../../pages/InvestorsPage";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: 'unset',
            maxHeight: 'unset',
            width: '75vw',
            height: '80vh',
            padding: 16,
            borderRadius: 24,
            overflow: 'hidden',
        },
    },
    dialogTitle: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 32,
        padding: 'unset',
        "& > span": {
            width: '100%',
            maxWidth: 250,
            color: 'black',
            fontWeight: 'bold',
            fontFamily: 'Inter',
            fontSize: '1.5rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
        }
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    cancelButton: {
        width: 100,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    saveAllButton: {
        minWidth: 100,
        width: 'auto',
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 20,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
        "& > span": {
            margin: 'unset',
        }
    },
    saveIcon: {
        width: 24,
        height: 24,
        color: theme.palette.primary.main,
    },
    updateButton: {
        width: 'auto',
        height: 36,
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    selected :{
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1rem',
        color: 'black',
    },
    table: {
        width: '100%',
        height: 'calc(100vh - 120px)',
        overflow: 'hidden',
    },
    headers: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        ...scrollbarStyle,
    },
    rows: {
        minWidth: '100%',
        width: 'fit-content',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    loadingText: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 700,
        color: theme.colors.neutral['900'],
    },
    loadingTextSub: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: '#9e9e9e',
    },
    loading: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.main,
    },
}));

export const collectionKey = `${partialKey}:deals`;

const ShareDealModal: React.FC<{ isOpen: boolean, onClose: () => void, }> = ({ isOpen, onClose }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { setDashboardsQueries } = useContext(DashboardsContext);
    const { defaultQueryTemplateValues } = useContext(DashboardQueriesContext);
    const { dashboard } = useContext(DashboardContext);
    const { workspaces } = useContext(GroupSettingsContext);
    const { getBulkWorkspaces, clearBulk } = useBulkWorkspaces();

    const [searching, setSearching] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const bulkWorkspaces = useMemo(() => getBulkWorkspaces(collectionKey), [getBulkWorkspaces]);

    const handleRefresh = useCallback(() => {
        const promises: Promise<any>[] = [];
        const queryDefaults = defaultQueryTemplateValues.find(defQuery => defQuery?.title === 'Investment Thesis');

        workspaces.forEach(workspace => {
            promises.push(
                refreshWorkspaceScoreFunc({
                    dashboardId: dashboard!.id,
                    workspaceId: workspace.id,
                    group: userGroup,
                    query: queryDefaults?.queryTemplate.replace('{dashboard_name}', dashboard!.title) || '{dashboard_name}',
                })
            );
        });

        Promise.all(promises).then(() => {
            setSearching(true);
        });
    // eslint-disable-next-line
    }, [workspaces, dashboard, userGroup]);

    const handleClose = useCallback(() => {
        timerRef.current = setTimeout(() => {
            clearBulk(collectionKey);
            onClose();
        }, 200);

        return () => {
            clearTimeout(timerRef.current);
        }
    // eslint-disable-next-line
    }, [clearBulk]);

    useEffect(() => {
        setSearching(false);
        clearBulk(collectionKey);
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (searching) {
            timerRef.current = setTimeout(() => {
                clearInterval(timerRef.current);
                getDashboardQueriesFunc(dashboard!.id).then(queriesData => {
                    setDashboardsQueries(prev => prev.map((query: DashboardQuery) => {
                        if (OverviewQueryTitles.includes(query.title)) {
                            const lookUpQuery = queriesData.find(q => q.dashboardId === dashboard!.id && q.title === query.title);

                            if (!!lookUpQuery)
                                return lookUpQuery;
                        }
                        return query;
                    }));
                    setSearching(false);
                });
                timerRef.current = setTimeout(() => {
                    clearInterval(timerRef.current);
                    setSearching(false);
                }, 45000);
            }, 15000);
        }

        return () => {
            clearInterval(timerRef.current);
        }
    // eslint-disable-next-line
    }, [searching]);

    if (!dashboard)
        return (<></>);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={handleClose}>
            <DialogTitle className={classes.dialogTitle}>
                <span>{`Share ${dashboard.title}`}</span>
                <Box width="100%" maxWidth="400px">
                    <WorkspacesSearchOrCreate search mediumFit onSearch={(value) => setSearch(value)} />
                </Box>
                <Box />
            </DialogTitle>
            {!!workspaces.length && (<Divider sx={{ margin: '8px 0'}} />)}
            <DialogContent className={classes.dialogContent}>
                {(searching) ? (
                    <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
                        <Typography className={classes.loadingText}>
                            {`Finding top matches for all investors`}
                        </Typography>
                        <Stack alignItems="center" justifyContent="center" mt={2} mb={6}>
                            <Typography className={classes.loadingTextSub}>
                                {'All scores may take up to a minute to calculate'}
                            </Typography>
                        </Stack>
                        <CircularProgress className={classes.loading} size={60} thickness={3} />
                    </Stack>
                ) : (
                    <Stack className={classes.table}>
                        {!!workspaces.length ? (
                            <InvestorsTable
                                dashboard={dashboard}
                                collectionKey={collectionKey}
                                search={search}
                                onRefreshScores={handleRefresh}
                                withFitScore noMailing noMenu />
                        ) : (
                            <Stack className={classes.headers} alignItems="flex-start" justifyContent="flex-start">
                                <WorkspacesTableHeader columns={FullTableHeaders}  />
                                <Stack className={classes.rows} alignItems="flex-start" justifyContent="flex-start">
                                    <NoWorkspacesFoundView noResult />
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%">
                    {!searching && (<>
                        <Typography className={classes.selected}>
                            {`${bulkWorkspaces.length} investor${bulkWorkspaces.length > 1 ? 's': ''} selected`}
                        </Typography>
                        <Button className={classes.cancelButton}
                            disabled={searching}
                            onClick={handleClose}> {'Cancel'} </Button>
                        {!searching && (<WorkspacesBulkShareEmail onClose={onClose} />)}
                    </>)}
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
}
export default ShareDealModal;