import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { PotentialCompany, UnassignedContent } from "../types/files";
// import { getUrlDestinationFunc } from "../lib/helper";

export type AttachmentType = {
  url: string;
  name: string;
  size?: number;
  contentType: string;
};

export type EmailDeal = {
  id: string;
  name: string;
  website?: string;
  stage?: string;
  deck?: AttachmentType;
  externalDecks?: AttachmentType[];
  email?: string;
  status?: string;
};

export const CommonDomains = ['docsend.com', 'linkedin.com', 'gmail.com', 'outlook.com'];

export const extractDomains = (text: string) => {
  const UrlPattern = /(https?:\/\/(?:www\.)?|www\.)([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(?=\/|$|\?|#)/gi;
  const results: string[] = [];
  let match;

  if (!(text && typeof text === 'string'))
    return results;

  if ((match = UrlPattern.exec(text)) !== null) {
    const fullUrl = match[0];
    const domain = match[0];

    if (/^@/.test(fullUrl))
      return results;

    if (!results.some(result => result === domain))
      results.push(domain);
  }

  return results;
}

const useEmailDealsExtraction = () => {
  const sanitizeUrl = useCallback((originUrl?: string) => originUrl?.replace(/^https?:\/\//, '')?.replace(/^www\./, '') || '', []);

  const matchAttachment = useCallback((companyName: string, attachments?: string[], solo?: boolean) => {
    if(!Array.isArray(attachments))
      return undefined;

    const fileNameTokens = attachments.map(attachment => attachment.split(/[\W/]+/).slice(0, -1)
      .filter((word, i, self) => (i === self.findIndex(item => item === word)))
      .filter(word => isNaN(Number(word)) && word.length >= 3)
      .map(word => word.trim().toLowerCase()));
    let deck: AttachmentType|undefined = undefined;

    if (!!fileNameTokens.length) {
      const companyWords = companyName.split(/[\W/]+/)
        .filter((word, i, self) => (i === self.findIndex(item => item === word)))
        .filter(word => isNaN(Number(word)) && word.length >= 3)
        .map(word => word.trim().toLowerCase());

      fileNameTokens.forEach((fNtokens, i) => {
        if (JSON.stringify(companyWords.sort()) === JSON.stringify(fNtokens.sort())
          || companyWords.some(wFToken => fNtokens.some(fnToken => fnToken.includes(wFToken)))
          || fNtokens.some(fNToken => companyWords.some(wFToken => wFToken.includes(fNToken)))
          || (solo && fNtokens.some(fNToken => ['pitch', 'deck'].some(wFToken => wFToken.includes(fNToken)))))
          deck = {url: '', name: attachments[i], contentType: 'application/pdf' } as AttachmentType;
      });
    }

    return deck;
  }, []);

  const registerDeal = useCallback((deals: EmailDeal[], emailContent: UnassignedContent, company?: PotentialCompany) => {
    if (!!company) {
      const fileAttachment = matchAttachment(company.name || '', emailContent?.attachments);
      const externalDecks: AttachmentType[] = company.externalLinks?.map(extDeck => ({ url: extDeck, name: extDeck, contentType: 'text/plain' })) ?? [];
      const deck = fileAttachment ?? externalDecks?.[0] ?? undefined;

      if (!!fileAttachment)
        externalDecks.push(fileAttachment);

      deals.push({
        id: uuidv4(),
        name: company.name || '',
        website: company.website || '',
        stage: undefined,
        deck,
        externalDecks,
        email: 'email',
        status: emailContent?.status || 'new' });
    }
  }, [matchAttachment]);

  const extractEmailDeals = useCallback((emailContent: UnassignedContent) => new Promise<EmailDeal[]>(async (resolve) => {
    const { companies } = emailContent;
    let deals: EmailDeal[] = [];

    if (!!companies?.length)
      companies.forEach(company => registerDeal(deals, emailContent, company));

    resolve(deals);
  }), [registerDeal]);

  return { sanitizeUrl, extractEmailDeals };
};

export default useEmailDealsExtraction;
