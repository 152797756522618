import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { DashboardQueriesContext } from "../../../contexts/DashboardQueriesContext";
import { DashboardsContext, OverviewQueryTitles } from "../../../contexts/DashboardsContext";
import useBulkDashboards from "../../../hooks/useBulkDashboards";
import useDashboards from '../../../hooks/useDashboards';
import { getGroupDashboardQueriesFunc, refreshWorkspaceScoreFunc, updateWorkspaceFunc } from "../../../lib/helper";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dashboard, DashboardQuery, Workspace } from "../../../types/files";
import NoDashboardsFoundView from "../../molecules/dashboards-empty/NoDashboardsFoundView";
import DashboardsSearchOrCreate from '../../molecules/dashboards-search-create/DashboardsSearchOrCreate';
import DashboardsTableHeader from "../../molecules/dashboards-table/DashboardsTableHeader";
import DashboardsTableRow from "../../molecules/dashboards-table/DashboardsTableRow";
import { TableHeaders } from "../../molecules/workspaces/SharedByMe";
import { collectionKey } from "../../pages/InvestorsPage";
import { FallbackLoading } from "../../templates/loader";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            minWidth: 1400,
            minHeight: 600,
            maxHeight: 600,
            width: 'min-content',
            height: 'fit-content',
            padding: 20,
            borderRadius: 24,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.5rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '8px 0',
        height: '100%',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    selected :{
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1rem',
        color: 'black',
    },
    table: {
        width: '100%',
        height: 'calc(100vh - 120px)',
        overflow: 'hidden',
    },
    headers: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        ...scrollbarStyle,
    },
    rows: {
        minWidth: '100%',
        width: 'fit-content',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    cancelButton: {
        width: 100,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    saveButton: {
        width: 100,
        borderRadius: 20,
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    loadingText: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 700,
        color: theme.colors.neutral['900'],
    },
    loadingTextSub: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: '#9e9e9e',
    },
    loading: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.main,
    },
}));

const AddCompaniesToInvestorModal: React.FC<{
    isOpen: boolean,
    workspace?: Workspace,
    onClose: () => void,
}> = ({ isOpen, workspace, onClose }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { mappedOverviewQueries, workspace: globalWorkspace, setWorkspace, dashboards, setDashboardsQueries } = useContext(DashboardsContext);
    const { defaultQueryTemplateValues } = useContext(DashboardQueriesContext);
    const { getBulkDashboards, assignToWorkspaces, clearBulk} = useBulkDashboards();
    const { filterDashboardList } = useDashboards();

    const [loading, setLoading] = useState<boolean>(false);
    const [searching, setSearching] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [dashboardArray, setDashboardArray] = useState<Dashboard[]>([]);
    const [sortBy, setSortBy] = useState<string>('Added');
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);

    const bulkCount = useMemo(() => getBulkDashboards(collectionKey).length, [getBulkDashboards]);

    const sortedDashboards = useMemo(() => {
        if (sortBy === 'Fit score') {
            return Array.from(new Map(
                [...dashboardArray].map(dashboard => {
                    const investmentThesis: { workspaceId: string, score: number}[]
                        = mappedOverviewQueries.get(`${dashboard.id}:Investment Thesis`) || [];
                    const score = investmentThesis?.find(iT => iT.workspaceId === localWorkspace!.id)?.score ?? 0;

                    return [dashboard, score];
                }))).sort((a, b) => b[1] - a[1]).map(entry => entry[0]);
        }

        // else sort by 'Added'
        return [...dashboardArray].sort((prev: Dashboard, next: Dashboard) => {
            const prevDate = prev.createdAt;
            const nextDate = next.createdAt;

            return nextDate.localeCompare(prevDate);
        });
    // eslint-disable-next-line
    }, [dashboardArray, sortBy]);

    const handleRefresh = useCallback((refresh?: boolean) => {
        if (!!localWorkspace) {
            const promises: Promise<any>[] = [];
            const queryDefaults = defaultQueryTemplateValues.find(defQuery => defQuery?.title === 'Investment Thesis');

            if (refresh)
                updateWorkspaceFunc(localWorkspace).then((updatedWorkspace?: Workspace) => setWorkspace({...updatedWorkspace!}));

            dashboards.forEach(dashboard => promises.push(
                refreshWorkspaceScoreFunc({
                    dashboardId: dashboard.id,
                    workspaceId: localWorkspace.id,
                    group: userGroup,
                    query: queryDefaults?.queryTemplate.replace('{dashboard_name}', dashboard.title) || '{dashboard_name}',
                })
            ));

            Promise.all(promises).then(() => {
                setSearching(true);
            });
        }
    // eslint-disable-next-line
    }, [dashboards, userGroup, localWorkspace]);

    const handleAssigntoWorkspace = useCallback(() => {
        setLoading(true);
        assignToWorkspaces(collectionKey, [localWorkspace!.id]).finally(() => {
            setLoading(false);
            onClose();
        });
    }, [assignToWorkspaces, localWorkspace, onClose]);

    useEffect(() => {
        setLoading(false);
        setSearching(false);
        clearBulk(collectionKey);
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (searching) {
            timerRef.current = setTimeout(() => {
                clearInterval(timerRef.current);
                getGroupDashboardQueriesFunc(userGroup).then((dashboardQueriesData) => {
                    setDashboardsQueries(dashboardQueriesData.filter((query: DashboardQuery) =>
                        OverviewQueryTitles.includes(query.title)
                    ));
                    setSearching(false);
                    setSortBy('Fit score');
                });
                timerRef.current = setTimeout(() => {
                    clearInterval(timerRef.current);
                    setSearching(false);
                    setSortBy('Fit score');
                }, 45000);
            }, 15000);
        }

        return () => {
            clearInterval(timerRef.current);
        }
    // eslint-disable-next-line
    }, [searching]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setDashboardArray(filterDashboardList(search || ''));
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    }, [filterDashboardList, search]);

    useEffect(() => {
        setLoading(!!timerRef.current && !dashboardArray);
    }, [dashboardArray]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={!loading && !searching ? onClose : undefined}>
            <DialogTitle className={classes.dialogTitle}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                    <span>{`Add companies to share with ${localWorkspace?.name}`}</span>
                    <Box width="100%" maxWidth="400px">
                        <DashboardsSearchOrCreate search mediumFit onSearch={(value) => setSearch(value)} />
                    </Box>
                    <Box minWidth="150px" />
                </Stack>
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                {loading ? (<FallbackLoading />) : (searching) ? (
                    <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
                        <Typography className={classes.loadingText}>
                            {`Finding top matches for ${localWorkspace!.name}`}
                        </Typography>
                        <Stack alignItems="center" justifyContent="center" mt={2} mb={6}>
                            <Typography className={classes.loadingTextSub}>
                                {'All scores may take up to a minute to calculate'}
                            </Typography>
                        </Stack>
                        <CircularProgress className={classes.loading} size={60} thickness={3} />
                    </Stack>
                ) : (
                    <Stack className={classes.table}>
                        {loading ? (<FallbackLoading />) : !!sortedDashboards.length ? (<>
                            <Stack className={classes.headers} alignItems="flex-start" justifyContent="flex-start">
                                <DashboardsTableHeader
                                    columns={TableHeaders}
                                    columnSorted={sortBy}
                                    onColumnSort={setSortBy}
                                    onRefreshScores={handleRefresh} />
                                <Stack className={classes.rows} alignItems="flex-start" justifyContent="flex-start">
                                    {sortedDashboards.map(dashboard => (
                                        <DashboardsTableRow
                                            dashboard={dashboard}
                                            workspace={workspace ?? undefined}
                                            columns={TableHeaders}
                                            collectionKey={collectionKey}
                                            isPublic
                                            key={'add-companies-to-investor-285-' + dashboard.id} />
                                    ))}
                                </Stack>
                            </Stack>
                        </>) : (
                            <Stack className={classes.headers} alignItems="flex-start" justifyContent="flex-start">
                                <DashboardsTableHeader columns={TableHeaders} />
                                <Stack className={classes.rows} alignItems="flex-start" justifyContent="flex-start">
                                    <NoDashboardsFoundView noResult />
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%">
                    {!searching && (
                        <Typography className={classes.selected}>
                            {`${bulkCount} compan${bulkCount > 1 ? 'ies': 'y'} selected`}
                        </Typography>
                    )}
                    <Button className={classes.cancelButton}
                        disabled={loading || searching}
                        onClick={onClose}> {'Cancel'} </Button>
                    <LoadingButton variant="contained"
                        className={classes.saveButton}
                        loading={loading}
                        disabled={searching}
                        onClick={handleAssigntoWorkspace}
                    > {'Add'} </LoadingButton>
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
}

export default AddCompaniesToInvestorModal;