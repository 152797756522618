import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import { Button, CircularProgress, Divider, Fade, Paper, Popper, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { AuthContext } from '../../../contexts/AuthContext';
import { DashboardsContext } from '../../../contexts/DashboardsContext';
import useBulkDashboards from '../../../hooks/useBulkDashboards';
import useDashboards from '../../../hooks/useDashboards';
import useExportCopy from '../../../hooks/useExportCopy';
import { removeWorkspaceFromDashboardFunc } from "../../../lib/helper";
import theme from '../../../theme';
import { DashboardQueriesType } from '../../../types/files';
import ConfirmDialog from '../../modals/ConfirmDialog';
import AddToInvestorsModal from '../../modals/multi-action/AddToInvestorsModal';
import CopyDealSnippets from '../../modals/multi-action/CopyDealSnippetsModal';
import ShareDealsModal from '../../modals/multi-action/ShareDealsModal';
import { collectionKey } from '../../organisms/dashboards/DashboardsView';
import ExportDocument from '../dashboard-export/ExportDocument';
import CompaniesStatusMenu from './CompaniesStatusMenu';

const useStyles = makeStyles((theme) => ({
    popup: {
        zIndex: 1300,
    },
    modal: {
        width: 270,
        height: 'fit-content',
        padding: '12px 8px 0px 8px',
        border: '1px solid lightgray',
    },
    title: {
        fontFamily: 'Poppins',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: 'black',
        marginLeft: 8,
    },
    button: {
        width: '100%',
        padding: '6px 8px !important',
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: 'black',
        textTransform: 'none',
        justifyContent: 'flex-start',
        "& > .MuiButton-startIcon": {
            "& > .MuiSvgIcon-root": {
                width: 24,
                height: 24,
                color: theme.palette.primary.light,
            }
        },
    },
}));

const CompaniesMultiAction = React.forwardRef<any>((props, ref) => {
    const classes = useStyles();
    const { userGroup: author } = useContext(AuthContext);
    const { workspace, setDashboards } = useContext(DashboardsContext);
    const { buildExportable, assignToWorkspaces, getBulkDashboards, clearBulk} = useBulkDashboards();
    const { copyBulkDashboards } = useExportCopy();
    const { deleteDashboard } = useDashboards();

    const [loading, setLoading] = useState<string>('');
    const [shareDealsModalOpen, setShareDealsModalOpen] = useState<boolean>(false);
    const [confirmRemoveOpen, setConfirmRemoveOpen] = useState<boolean>(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
    const [copyDealSnippetsModalOpen, setCopyDealSnippetsModalOpen] = useState<boolean>(false);
    const [addToInvestorsModalOpen, setAddToInvestorsModalOpen] = useState<boolean>(false);
    const [queryTitles, setQueryTitles] = useState<string[]>(['Description']);
    const anchorRef = useRef<any>(ref);

    const { actions, liveFeed, homePage, xAdjust } = props as { actions?: string[], liveFeed?: boolean, homePage: boolean, xAdjust: number };

    const yPosition = anchorRef?.current?.current?.offsetHeight + anchorRef?.current?.current?.offsetTop;
    const xPosition = (liveFeed ? 820 : homePage ? 400 : 300) + (xAdjust ?? 0);

    const documentName = `Notissia Dashboard Export ${moment().format('(M-D-YYYY)')}`;

    const showAction = (action: string) => actions?.join(';').includes(action);

    const bulkDashboards = useMemo(() => getBulkDashboards(collectionKey), [getBulkDashboards]);

    const handleCopy = useCallback(() => {
        setLoading('copy');
        copyBulkDashboards(collectionKey, queryTitles).finally(() => {
            setLoading('');
            setCopyDealSnippetsModalOpen(false);
        });
    }, [copyBulkDashboards, queryTitles]);

    const handleAssigntoWorkspace = useCallback((workspaceIds: string[]) => {
        setLoading('assign');
        assignToWorkspaces(collectionKey, workspaceIds).finally(() => {
            setLoading('');
            setAddToInvestorsModalOpen(false);
        });
    }, [assignToWorkspaces]);

    const handleExportPDF = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setLoading('export');
        buildExportable(collectionKey, queryTitles)
            .then((bulkDashboards: DashboardQueriesType[]) => pdf(
                <ExportDocument bulkDashboards={bulkDashboards} author={author} />
            ).toBlob().then(blob => saveAs(blob, `${documentName}.pdf`)))
             .finally(() => setLoading(''));
    }, [author, buildExportable, documentName, queryTitles]);

    const handleRemovefromWorkspace = useCallback(() => {
        const promises: Promise<boolean>[] = [];
        const bulkDashboards = getBulkDashboards(collectionKey);

        setLoading('remove');
        bulkDashboards.forEach(({dashboard}) => promises.push(removeWorkspaceFromDashboardFunc({
            dashboardId: dashboard.id, workspaceId: workspace!.id, group: author
        })));
        Promise.all(promises).finally(() => {
            setLoading('');
            setConfirmRemoveOpen(false);
        });
    }, [author, getBulkDashboards, workspace]);

    const handleDelete = useCallback(() => {
        const promises: Promise<boolean>[] = [];
        const bulkDashboards = getBulkDashboards(collectionKey);

        setLoading('delete');
        bulkDashboards.forEach(({dashboard}) => promises.push(deleteDashboard(dashboard.id)));
        Promise.all(promises).then(() => {
            const bulkDashboardIds = bulkDashboards.map(({dashboard}) => dashboard.id);

            setDashboards(prev => prev.filter(dashboard => !bulkDashboardIds.includes(dashboard.id)))
            clearBulk(collectionKey);
        }).finally(() => {
            setLoading('');
            setConfirmDeleteOpen(false);
        });
    // eslint-disable-next-line
    }, [getBulkDashboards]);

    useEffect(() => {
        setLoading('');
        clearBulk(collectionKey);

        return () => {
            setLoading('');
            clearBulk(collectionKey);
        };
    // eslint-disable-next-line
    }, []);

    return (<>
        <Popper open={!!bulkDashboards.length} className={classes.popup} transition
            sx={{ top: `${yPosition}px !important`, left: `calc(100% - ${xPosition}px) !important` }}>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                    <Paper className={classes.modal} elevation={3} square={false}>
                        <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="center" width="100%" mb={1}>
                            <Typography className={classes.title}>{`${bulkDashboards.length} compan${bulkDashboards.length > 1 ? 'ies' : 'y'} selected`}</Typography>
                            <Divider sx={{ width: '100%' }}/>
                            <Stack direction="column" alignItems="flex-start" justifyContent="center" width="100%">
                                {showAction('Share deals') && (
                                    <Button className={classes.button}
                                        startIcon={<ShareIcon width={24} height={24} stroke={theme.palette.primary.light} />}
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setShareDealsModalOpen(true);
                                        }}> {'Share deals'} </Button>
                                )}
                                {showAction('Move to') && (
                                    <Stack direction="row" alignItems="center" justifyContent="flex-start" px="4px !important" width="100%">
                                        <ArrowForwardIcon sx={{ width: 24, height: 24, color: theme.palette.primary.light }} />
                                        <Typography className={classes.button} sx={{ width: 'fit-content !important'}}> {'Move to: '} </Typography>
                                        <CompaniesStatusMenu />
                                    </Stack>
                                )}
                                {showAction('Copy deals snippet') && (
                                    <Button className={classes.button}
                                        startIcon={<ContentCopyIcon />}
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setCopyDealSnippetsModalOpen(true);
                                        }}> {'Copy deals snippet'} </Button>
                                )}
                                {showAction('Add to an investor workspace') && (
                                    <Button className={classes.button}
                                        startIcon={<AccountCircleIcon />}
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setAddToInvestorsModalOpen(true);
                                        }}
                                    > {'Add to an investor workspace'} </Button>
                                )}
                                {showAction('Download as PDF') && (
                                    <Button className={classes.button}
                                        startIcon={(loading === 'export') ? (
                                            <CircularProgress size="24px" sx={{ color: theme.palette.primary.light }} />
                                        ): (<IosShareRoundedIcon />)}
                                        onClick={handleExportPDF}
                                    > {'Download as PDF'} </Button>
                                )}
                                {showAction('Remove from workspace') && (
                                    <Button variant="text"
                                        className={classes.button}
                                        startIcon={<DeleteOutlineIcon
                                            sx={{ color: `${theme.colors.error['500']} !important` }} />}
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setConfirmRemoveOpen(true);
                                        }}
                                        sx={{ color: `${theme.colors.error['500']} !important` }}
                                    > {'Remove from workspace'} </Button>
                                )}
                                {showAction('Delete') && (
                                    <Button variant="text"
                                        className={classes.button}
                                        startIcon={<DeleteOutlineIcon
                                            sx={{ color: `${theme.colors.error['500']} !important` }} />}
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setConfirmDeleteOpen(true);
                                        }}
                                        sx={{ color: `${theme.colors.error['500']} !important` }}
                                    > {'Delete'} </Button>
                                )}
                                {!!actions?.length && (
                                    <Divider sx={{ width: '100%', margin: '4px 0 !important' }}/>
                                )}
                                <Button className={classes.button}
                                    startIcon={<ClearIcon />}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        clearBulk(collectionKey);
                                    }}
                                    sx={{ color: `${theme.palette.primary.light} !important` }}
                                > {'Clear selection'} </Button>
                            </Stack>
                        </Stack>
                    </Paper>
                </Fade>
            )}
        </Popper>
        {shareDealsModalOpen && (
            <ShareDealsModal
                isOpen={shareDealsModalOpen}
                onClose={() => setShareDealsModalOpen(false)} />
        )}
        {copyDealSnippetsModalOpen && (
            <CopyDealSnippets
                isOpen={copyDealSnippetsModalOpen}
                queryTitles={queryTitles}
                loading={loading === 'copy'}
                onQueryTitles={setQueryTitles}
                onCopy={handleCopy}
                onClose={() => setCopyDealSnippetsModalOpen(false)} />
        )}
        {addToInvestorsModalOpen && (
            <AddToInvestorsModal
                isOpen={addToInvestorsModalOpen}
                loading={loading === 'assign'}
                onAdd={handleAssigntoWorkspace}
                onClose={() => setAddToInvestorsModalOpen(false)} />
        )}
        <ConfirmDialog
            title="Remove from workspace confirmation"
            content="Are you sure you want to remove the selected companies from its workspace?"
            open={confirmRemoveOpen}
            loading={loading === 'remove'}
            confirmLabel="Remove"
            confirmCallback={handleRemovefromWorkspace}
            cancelCallback={() => setConfirmRemoveOpen(false)}
        />
        <ConfirmDialog
            title="Delete confirmation"
            content="Are you sure you want to delete the selected companies?"
            open={confirmDeleteOpen}
            loading={loading === 'delete'}
            confirmCallback={handleDelete}
            cancelCallback={() => setConfirmDeleteOpen(false)}
        />
    </>);
});

export default CompaniesMultiAction;
