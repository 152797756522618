import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Button, Fade, IconButton, Slide, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { createPortal } from "react-dom";
import classnames from "classnames";
import theme from "../../../theme";
import { SubscriptionContext } from "../../../contexts/SubscriptionContext";
import { useHistory } from "react-router-dom";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const useStyles = makeStyles(() => ({
    container: {
        position: 'absolute',
        top: 25,
        left: 'calc(50vw - calc(500px / 2))',
        zIndex: 1200,
    },
    normal: {
        left: 'calc(50vw - calc(500px / 2))',
    },
    wide: {
        left: 'calc(50vw - calc(1050px / 2))',
    },
    modal: {
        position: 'relative',
        height: 'auto',
        padding: '8px 16px',
        border: '2px solid',
        borderColor: theme.colors.secondary[300],
        borderRadius: 8,
        background: theme.colors.secondary[50],
    },
    limit: {
        width: '1050px',
    },
    upgrade: {
        width: '500px',
    },
    text: {
        fontSize: '1.2rem',
        color: theme.colors.secondary[800],
    },
    updateButton: {
        padding: '4px 20px',
        borderRadius: 20,
        background: theme.colors.secondary[100],
        color: theme.colors.secondary[800],
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        "&:hover": {
            background: theme.colors.secondary[100],
            opacity: '0.8',
        }
    },
    closeButton: {
        position: 'relative',
        color: theme.colors.secondary[800],
        bottom: 75,
        left: 1030,
    },
}));

const ProPlanModal: React.FC<{
    variant: string | 'limit' | 'upgrade',
    exceptPlan?: string[],
    flags?: any,
 }> = ({ variant, exceptPlan, flags }) => {
    const classes = useStyles();
    const history = useHistory();
    const { subscription, usage } = useContext(SubscriptionContext);

    const [open, setOpen] = useState<boolean>(false);
    const [timeOutState, setTimeOutState] = useState<NodeJS.Timeout | string | number | undefined>(undefined);

    const handleUpgrade = useCallback(() => {
        history.push("/subscriptions");
        setOpen(false);
    }, [history]);

    useEffect(() => {
        if (!isNaN(flags?.monthlyDashboardLimit) && subscription && usage && !timeOutState) {
            setTimeOutState(setTimeout(() => {
                clearTimeout(timeOutState);
                switch (variant) {
                    case 'limit':
                        setOpen((flags?.monthlyDashboardLimit > 0) && ((usage?.dashboardsCreated ?? 0) >= flags?.monthlyDashboardLimit));
                        break;
                    case 'upgrade':
                        if (exceptPlan?.map(plan => plan.toLowerCase()).includes(subscription.planName!.toLowerCase()))
                            setOpen(true);
                        break;
                }
            }, 1000));
        }
        // eslint-disable-next-line
    }, [flags, subscription, usage]);

    return createPortal(<>
        <Box className={classnames(classes.container, variant === "limit" ? classes.wide : classes.normal)}>
            <Slide direction="down" in={open} mountOnEnter unmountOnExit>
                <Stack direction="row" className={classnames(classes.modal, variant === "limit" ? classes.limit : classes.upgrade)}
                    alignItems="center" justifyContent="space-between">
                    {variant === "limit" ? (
                        <Stack alignItems="flex-start">
                            <Typography className={classes.text}>
                                {`You've reached your monthly dashboard limit. Upgrade to our `}
                                <strong>Pro plan</strong>
                                {' for up to 200 dashboards per month.'}
                            </Typography>
                        </Stack>
                    ) : (
                        <Typography className={classes.text}>To see data here, upgrade to our <strong>Pro plan</strong>.</Typography>
                    )}
                    <Button className={classes.updateButton} onClick={handleUpgrade}>Upgrade</Button>
                </Stack>
            </Slide>
            {variant === 'limit' && (
                <Fade in={open}>
                    <IconButton className={classes.closeButton} onClick={() => setOpen(false)}>
                        <CancelRoundedIcon fontSize="medium" />
                    </IconButton>
                </Fade>
            )}
        </Box>
    </>, document.body);
}

export default withLDConsumer()(ProPlanModal);