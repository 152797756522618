import { Box, Button, Chip, Divider, List, ListItem, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import {AuthContext} from '../../../contexts/AuthContext';
import { getUserEmail } from '../../../helpers/authUser';

const useStyles = makeStyles((theme) => ({
    titlePlan: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: '2rem',
        color: theme.colors.primary[600],
    },
    featureList: {
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: '1rem',
    },
    featureCount: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: '1rem',
        color: theme.colors.primary[600],
    },
    discountChip: {
        height: 36,
        width: 96,
        borderRadius: 32,
        borderWidth: 3,
        padding: 8,
        fontSize: 18,
        fontWeight: 'bold',
        textTransform: 'none',
        background: theme.colors.neutral[50],
        color: theme.colors.cyan[400],
    },
    rateText: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        color: theme.colors.neutral[600],
    },
    discountedPriceText: {
        fontFamily: 'Inter',
        fontWeight: 'bold',
        fontSize: '1.8rem',
        color: theme.colors.cyan[400],
    },
    originalPriceText: {
        position: 'relative',
        display: 'inline-block',
        fontFamily: 'Inter',
        fontWeight: 'bold',
        fontSize: '1.7rem',
        color: theme.colors.neutral[400],
    },
    originalPriceSlash: {
        position: 'relative',
        width: '100%',
        height: 0,
        top: -24,
        left: 8,
        borderTop: `4px solid ${theme.colors.neutral[400]}`,
        transform: 'rotate(-145deg)',
    },
    planButton: {
        width: 260,
        height: 50,
        borderRadius: 32,
        borderWidth: 3,
        textTransform: 'none',
        padding: '8px 24px',
        fontSize: '1.25rem',
        fontWeight: 'bold',
    },
    daysLeft: {
        marginTop: 8,
        marginBottom: -24,
        fontSize: '1rem',
        fontFamily: 'Inter',
        fontWeight: '500',
        color: theme.colors.warning[500],
    },
    url: {
        color: theme.colors.cyan[400],
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'none !important',
        }
    },
}));

const ProPlan: React.FC<{
    onSelectPlan?: () => void,
    selected: boolean,
    admin: string,
    daysLeft?: number,
    cancelled: boolean,
    hasPayment: boolean,
    loaded: boolean,
}> = ({ onSelectPlan, selected, admin, daysLeft, cancelled, hasPayment, loaded }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [isAdmin, setAdmin] = useState<boolean>(false);

    useEffect(() => {
        if (user && admin)
            setAdmin(admin === getUserEmail(user).toLowerCase());
    }, [admin, user]);

    return (<Box position="relative">
        <Stack direction="row" alignItems="center" justifyContent="space-between" p={2} height="80px">
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="flex-start">
                <Typography className={classes.titlePlan}>Pro</Typography>
                <Chip className={classes.discountChip} label="—50%" />
            </Stack>
            <Stack direction="column" alignItems="center" justifyContent="flex-end">
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" mb="-4px">
                    <Typography className={classes.discountedPriceText}>$49</Typography>
                    <Typography component="div" className={classes.originalPriceText}>
                        {'$99'}<Typography component="div" className={classes.originalPriceSlash} />
                    </Typography>
                </Stack>
                <Typography className={classes.rateText}>Per user / month</Typography>
            </Stack>
        </Stack>
        <Divider />
        <Stack alignItems="flex-start" p={2} height="304px">
            <Typography fontFamily="Poppins" fontWeight="bold" fontSize="1.2rem">What's included:</Typography>
            <Typography className={classes.featureList} mt={2}>Everything from the free plan plus:</Typography>
            <List sx={{ width: '100%', margin: '0 32px', listStyleType: 'disc' }}>
                <ListItem sx={{ paddingLeft: '0px !important', display: 'list-item' }}>
                    <Typography className={classes.featureCount} display="inline">200 dashboards</Typography>
                    <Typography className={classes.featureList} display="inline"> per month</Typography>
                </ListItem>
                <ListItem sx={{ paddingLeft: 'unset', display: 'list-item' }}>
                    <Typography className={classes.featureList}>Zapier integration to sync data to CRMs and other productivity apps</Typography>
                </ListItem>
                <ListItem sx={{ paddingLeft: 'unset', display: 'list-item' }}>
                    <Typography className={classes.featureList}>Metrics benchmarking</Typography>
                </ListItem>
                <ListItem sx={{ paddingLeft: 'unset', display: 'list-item' }}>
                    <Typography className={classes.featureList}>Connect to cloud storage providers for automated file syncing</Typography>
                </ListItem>
            </List>
        </Stack>
        <Divider />
        {loaded ? (
            <Stack alignItems="center" justifyContent="center" p={2} height="112px">
                {selected ? ((!isAdmin && !!daysLeft && !hasPayment) ? (
                    <Typography color="grey" textAlign="center">
                        {'Contact your account administrator to upgrade to Pro '}
                        (<Typography
                            component="a"
                            className={classes.url}
                            href={'mailto:' + admin}
                            target="_blank"
                            rel="noopener noreferrer"
                            display="inline"
                        >{admin}</Typography>)
                    </Typography>
                ) : (
                    <Button variant="contained" className={classes.planButton} disabled>Current plan</Button>
                )) : (!isAdmin) ? (<>
                    <Typography color="grey" textAlign="center">
                        {'Contact your account administrator to upgrade to Pro '}
                        (<Typography
                            component="a"
                            className={classes.url}
                            href={'mailto:' + admin}
                            target="_blank"
                            rel="noopener noreferrer"
                            display="inline"
                        >{admin}</Typography>)
                    </Typography>
                </>) : (
                    <Button variant="contained" className={classes.planButton} onClick={onSelectPlan}>
                        Upgrade to Pro plan
                    </Button>
                )}
                {(!!daysLeft && !cancelled) && (
                    <Typography className={classes.daysLeft}>{`${daysLeft} day${daysLeft > 1 ? 's': ''} left on your free trial`}</Typography>
                )}
            </Stack>
        ) : (
            <Box height="112px" />
        )}
    </Box>);
}

export default ProPlan;