import { Box, Button, Chip, Collapse, Stack, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DashboardQueriesContext } from "../../../contexts/DashboardQueriesContext";
import { MetricData, NoteMetric } from "../../../types/search";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { ReactComponent as BarGraph } from '../../../assets/icons/barGraph.svg';
import { DashboardContext } from "../../../contexts/DashboardContext";
import theme from "../../../theme";
import storage from "../../../utils/storage";
import MetricsTimelineV2, { MetricsData } from "../../atoms/MetricsTimelineV2";
import AddCustomMetricsModal from "../../modals/dashboard-queries/AddCustomMetricsModal";
import FinancialTimelineMetricsModal from "../../modals/dashboard-timeline/FinancialTimelineMetricsModal";
import { DateFmt, DollarMetrics, PercentMetrics, RevenueSubcategories } from "../../molecules/dashboard-query-answer/MetricsAnswer";
import FoundedElement from "../../molecules/dashboard-timeline-elements/FoundedElement";
import MetricsElementV2 from "../../molecules/dashboard-timeline-elements/MetricsElementV2";
import FundingElementV2 from "../../molecules/dashboard-timeline-elements/FundingElementV2";
import { scrollbarStyle } from "../../../shared/dashboard";

type FoundedType = {
    year: string,
    month?: string,
}

type FundingType = {
    funding_date: Moment,
    funding_type: string,
    money_raised?: string,
    investor_list: string,
}

type TimelineData = {
    [tlDate: string]: Array<{
        founded?: FoundedType,
        funding?: FundingType,
        metrics?: MetricsData,
    }>
}

type TimelineItem = {
    foundedItems: FoundedType[],
    fundingItems: FundingType[],
    metricItems: MetricsData[],
}

const useStyles = makeStyles(() => ({
    expandIcon: {
        fill: '#666666 !important',
        alignItems: 'center !important',
        justifyContent: 'right',
        cursor: 'pointer',
    },
    addSectionButton: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: '500 !important',
        color: theme.palette.primary.light,
        background: 'transparent',
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
        "&:hover": {
            background: 'transparent',
        }
    },
    addButton: {
        minWidth: 150,
        width: 'auto',
        height: 40,
        borderRadius: 24,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    icon: {
        width: 16,
        height: 'auto',
        stroke: theme.palette.primary.light,
    },
    viewsButton: {
        width: 'auto',
        maxWidth: '100%', 
        height: 40,
        borderRadius: 24,
        borderColor: theme.colors.neutral['100'],
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['700'],
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
        padding: '0 16px', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis', 
        "&:hover": {
            background: theme.colors.neutral['200'],
        },
    },
    selectedButton: {
        background: theme.palette.primary.main,
        color: 'white',
        "&:hover": {
            background: theme.colors.primary['800'],
        },
    },
    legendText: {
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: '600',
        color: theme.colors.neutral['600'],
        whiteSpace: 'nowrap',
    },
    legendChip: {
        width: 50,
        height: 10,
    },
    noText: {
        fontFamily: 'Inter',
        fontSize: '1.3rem',
        fontWeight: '600',
        color: theme.colors.neutral['600'],
    },
    otherText: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 'normal',
        color: theme.colors.neutral['600'],
        textAlign: 'center',
    },
}));

const TimelineDateFormat = 'MMM YYYY';
const QueryElements = ['Funding', 'Note Metrics', 'Founded'];

const DashboardMetricsTimelineV2: React.FC<{}> = () => {
    const classes = useStyles();
    const { dashboard, isPublicView } = useContext(DashboardContext);
    const { queries } = useContext(DashboardQueriesContext);

    const [addMetricModalOpen, setAddMetricModalOpen] = useState<boolean>(false);
    const [isExpanded, setExpanded] = useState<boolean>(false);
    const [withFounded, setWithFounded] = useState<boolean>(false);
    const [timelineHeaders, setTimelineHeaders] = useState<string[]>([]);
    const [modalHeaders, setModalHeaders] = useState<string[]>([]);
    const [timelineView, setTimelineView] = useState<string[]>(['all']);
    const [fundingData, setFundingData] = useState<FundingType[]>([]);
    const [metricsData, setMetricsData] = useState<MetricsData[][]>([]);
    const [timelineData, setTimelineData] = useState<TimelineData>({});
    const [timelineItems, setTimelineItems] = useState<TimelineItem[]>([]);
    const [addCustomMetricsModalOpen, setAddCustomMetricsModalOpen] = useState<boolean>(false);
    const [customValues, setCustomValues] = useState<MetricsData|undefined>(undefined);

    const queryMap = useMemo(() => {
        let queryMap: { [key: string]: any }|null = null;

        if (!!dashboard && queries.filter((query) => QueryElements.includes(query.title))?.some((query) => !!query.answer)) {
            queryMap = queries.reduce((result, query) => {
                if (QueryElements.includes(query.title)) {
                    if (query.title === 'Note Metrics') {
                        const answer = query?.answer ? JSON.parse(query.answer)?.answer : null;
                        const history = query?.history ? JSON.parse(query.history) : [];

                        result[query.title] = [answer, ...history].map((history: any) => history?.answer ?? history) ?? [];
                    } else {
                        result[query.title] = query?.answer ? JSON.parse(query.answer)?.answer : null;
                    }
                }

                return result;
            }, {} as any);
        }

        return queryMap;
    }, [dashboard, queries]);

    const filteredMetricsData =  useMemo(() => metricsData.map(subMetrics =>
        subMetrics.filter(({ metric }) => timelineView.join('|').includes(metric) || timelineView[0] === 'all')
    ), [metricsData, timelineView]);

    const maxMetricValue =  useMemo(() => {
        let max = -Infinity;

        filteredMetricsData.flat().forEach(({ metric, value }) => {
            if (!['fundraising', 'valuation'].join('|').includes(metric.toLowerCase())) {
                let numericValue = parseFloat(value.replace(/[$,]/g, ""));
    
                if (!isNaN(numericValue)) 
                  max = Math.max(max, numericValue);                
            }
        });

        return max === -Infinity ? 0 : max;
    }, [filteredMetricsData]);

    const metricViews = useMemo(() => Array.from(new Set(metricsData
        .filter(([{metric}]) => !['fundraising', 'valuation'].join('|').includes(metric.trim().toLowerCase()))
        .map(([{metric}]) => metric.trim()))).sort()
    , [metricsData]);

    const metricColors = useMemo(() => metricViews.reduce((colors, metric, index) => {
        const colorIndex = index > 9 ? 900 : 100 * index;

        colors.set(metric.trim().toLowerCase(), index === 0 ? theme.colors.neutral[100] : theme.colors.primary[colorIndex]);

        return colors;
    }, new Map<string, string>()), [metricViews]);

    const fundingViews = useMemo(() => {
        const hasFundraising = fundingData.some(({funding_type}) => funding_type.toLowerCase() === 'fundraising');
        const hasValuation = fundingData.some(({funding_type}) => funding_type.toLowerCase() === 'valuation');
        const hasPriorFunding = fundingData.some(({funding_type}) => !['fundraising', 'valuation'].join('|').includes(funding_type.toLowerCase()));

        return [
            ...(hasFundraising ? ['Fundraising'] : []),
            ...(hasValuation ? ['Valuation'] : []),
            ...(hasPriorFunding ? ['Prior Funding'] : [])
        ];
    }, [fundingData]);

    const timelineViews = useMemo(() => [...metricViews, ...fundingViews].sort(), [fundingViews, metricViews]);

    const hasViewableElements = useMemo(() => timelineItems.filter(({fundingItems, metricItems}) => 
        !!fundingItems.length || !!metricItems.length
    ).length > 0, [timelineItems]);

    const timelineElements = useMemo(() => {
        const elements: (ReactNode | undefined)[] = [];

        timelineItems.forEach(({foundedItems, fundingItems, metricItems}, i, self) => {
            if (!!foundedItems.length && self.length > 1) {
                elements.push(<FoundedElement items={foundedItems}
                    key={'dashboard-metrics-timeline-v2-201-' + i} />);
            }

            if (!!fundingItems.length || !!metricItems.length) {
                elements.push(
                    <Stack direction="column" spacing={1} alignItems="center"
                        key={'dashboard-metrics-timeline-v2-207-' + i}>
                        {!!fundingItems.length && (
                            <FundingElementV2 
                                items={fundingItems}                                
                                onEdit={(customValues) => {
                                    setCustomValues(customValues);
                                    setAddCustomMetricsModalOpen(true);
                                }} />
                        )}
                        {!!metricItems.length && (
                            <MetricsElementV2
                                items={metricItems}
                                metricColors={metricColors}
                                maxMetricValue={maxMetricValue}
                            />
                        )}
                    </Stack>
                );
            }
        });

        return elements;
    }, [timelineItems, metricColors, maxMetricValue]);

    const handleTimelineView = useCallback((view: string) => setTimelineView(prev => {
        if (view === 'all')
            return ['all'];

        if (prev.length === 1 && prev[0] === view)
            return ['all'];

        if (prev.includes(view)) {
            return prev.length > 1 ? prev.filter(v => v !== view) : ['all'];
        }

        return [...prev.filter(v => v !== 'all'), view];
    }), []);

    const extractFoundedElement = useCallback((tlData: TimelineData, founded: any) => {
        if (typeof founded === 'number') {
            const year = moment({year: founded}).format('YYYY');

            tlData[year] = [];
            tlData[year].push({founded: { year }});

            return true;
        }

        return false;
    }, []);

    const extractMetricsElements = useCallback((tlData: TimelineData, metrics: any[]) => {
        if (Array.isArray(metrics) && !!metrics.length) {
            const historyRecord = metrics.splice(1) ?? [];
            const historyList = Object.entries(metrics[0] ?? {})
                .map(([metric, value]) => {
                    const filteredHistory = historyRecord
                        .map(history => history[metric as keyof NoteMetric] as MetricData)
                        .filter(history => !!history && Object.values(history).every(value => !!value));
                    
                    if (!!(value as MetricData).value)
                        filteredHistory.push({ ...value as MetricData });
    
                    const sortedHistory = filteredHistory
                        .sort((prev, next) => moment(prev.date).isAfter(moment(next.date)) ? -1 : 1);
    
                    return ({ metric, history: sortedHistory });
                });
            
            const revenueHistory = historyList.find(metrics => metrics.metric === 'Revenue')?.history;
            const subRevenueHistories = historyList.filter(metrics =>
                RevenueSubcategories.map(cat => cat.toLowerCase()).includes(metrics.metric.trim().toLowerCase()))
                    ?.map(metrics => ({ metric: metrics.metric, history: metrics.history }));
            const acvHistory = historyList.find(metrics => metrics.metric === 'ACV')?.history;
            const nullMetrics: {metric: string, history: MetricData}[] = [];

            if (Array.isArray(revenueHistory)) {
                let sameRevenue: string[] = [];
                let sameSubRevenue: string[] = [];

                subRevenueHistories?.forEach(({metric, history}, mainIndex, self) => {
                    if (moment(revenueHistory?.[0]?.date).format(DateFmt) === moment(history?.[0]?.date).format(DateFmt)
                        && revenueHistory?.[0]?.units === history?.[0]?.units
                        && revenueHistory?.[0]?.value === history?.[0]?.value)
                        sameRevenue.push(metric);

                    const matchingMetrics = self.filter(({history: otherHistory}, otherIndex) =>
                            otherIndex !== mainIndex && history?.[0]?.value === otherHistory?.[0]?.value
                        ).map(({metric}) => metric);

                    if (matchingMetrics.length > 0)
                        sameSubRevenue = Array.from(new Set([...sameSubRevenue, metric, ...matchingMetrics]));
                });

                if (sameRevenue.length === 1) {
                    nullMetrics.push({ metric: 'Revenue', history: revenueHistory?.[0] });
                } else if (sameRevenue.length > 1 || !!sameSubRevenue.length) {
                    subRevenueHistories?.forEach(({metric, history}) => {
                        if ([...sameRevenue, ...sameSubRevenue].join(',').includes(metric))
                            nullMetrics.push({ metric, history: history?.[0] });
                    });
                }

                acvHistory?.forEach(acvH => {
                    revenueHistory.forEach(revH => {
                        if (moment(revH?.date).format(DateFmt) === moment(acvH?.date).format(DateFmt)
                            && revH?.units === acvH?.units && revH?.value === acvH?.value)
                            nullMetrics.push({ metric: 'ACV', history: acvH });
                    });
                });
            }

            nullMetrics.forEach(metricsData => { 
                if (!!metricsData.history?.value) 
                    metricsData.history.value = null;
            });

            historyList.forEach(({metric, history}) => {
                history.forEach(({date, units, value}) => {
                    const tlDate = moment(date || new Date());
                    const tlDateFmt = tlDate.format(TimelineDateFormat);
                    let tlValue: string|number|null = value;
                    let tlUnits = units ?? undefined;

                    if (tlValue === null) {
                        tlUnits = '#';
                        tlValue = '-';
                    } else {
                        let cleanInput = String(tlValue!).replace(/[^0-9bmkt.$%-]/gi, '').toLowerCase();
                        let multiplier = 1;

                        if (cleanInput.includes('t'))
                            multiplier = Math.pow(10, 12);
                        else if (cleanInput.includes('b'))
                            multiplier = Math.pow(10, 9);
                        else if (cleanInput.includes('m'))
                            multiplier = Math.pow(10, 6);
                        else if (cleanInput.includes('k'))
                            multiplier = 1000;

                        const numericValue = multiplier * parseFloat(cleanInput.replace(/[^0-9.-]/g, ''));

                        if (!tlUnits && DollarMetrics.includes(metric)) {
                            tlUnits = '$';
                            tlValue = `${numericValue < 0 ? '-' : ''}$${Math.abs(numericValue).toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else if (!tlUnits && PercentMetrics.includes(metric)) {
                            tlUnits = '%';
                            tlValue = `${numericValue.toFixed(1)}%`;
                        } else {
                            if (tlUnits === '$')
                                tlValue = `${numericValue < 0 ? '-' : ''}$${Math.abs(numericValue).toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            else if (tlUnits === '%')
                                tlValue = `${numericValue.toFixed(1)}%`;
                            else
                                tlValue = `${numericValue}`;
                        }
                    }

                    if (!tlData[tlDateFmt])
                        tlData[tlDateFmt] = [];
                    if (!tlData[tlDateFmt].find(({ metrics }) => metrics?.metric === metric)) {
                        tlData[tlDateFmt].push({ metrics: { metric, value: tlValue, date: tlDate, units: tlUnits } });                        
                        if (['fundraising', 'valuation'].join('|').includes(metric.toLowerCase())
                            && (tlValue !== '0' && tlValue !== '-')) {
                            tlData[tlDateFmt].push({funding: { funding_type: metric, funding_date: tlDate, money_raised: tlValue, investor_list: "" }});
                        } 
                    }
                });
            });
        }
    }, []);

    const extractFundingElements = useCallback((tlData: TimelineData, funding: any[]) => {
        if (Array.isArray(funding) && !!funding.length) {
            funding.filter((funding: any) => !!funding.announced_date)
                .forEach((funding: any) => {
                    const {announced_date, funding_type, money_raised, investor_list} = funding;
                    const tlDate = moment({
                        year: announced_date.year, month: announced_date.month - 1, day: announced_date.day,
                    });
                    const tlDateFmt = tlDate.format(TimelineDateFormat);
                    const moneyRaised = money_raised ? `US$${(funding.money_raised >= 1e6)
                        ? (funding.money_raised / 1e6).toFixed(1) + 'M': (funding.money_raised >= 1e3)
                            ? (funding.money_raised / 1e3).toFixed(1) + 'K': funding.money_raised.toString()}`
                                .replace('.0', '') : undefined;
                    const investorList = investor_list?.map((investor: any) => investor.name)?.join(", ");

                    if (!tlData[tlDateFmt])
                        tlData[tlDateFmt] = [];
                    tlData[tlDateFmt].push({funding: {funding_type, funding_date: tlDate, money_raised: moneyRaised, investor_list: investorList}});
                });
        }
    }, []);

    const buildTimelineItems = useCallback((timelineView: string[]) => {
        const tlHeaders: string[] = ['Metrics'];
        const mdHeaders: string[] = ['Metrics'];
        const rowsData: MetricsData[][] = [];
        const fundingsData: FundingType[] = [];

        const tlItems: TimelineItem[] = Object.entries(timelineData)
            .sort(([dateA], [dateB]) => moment(dateA, TimelineDateFormat).diff(moment(dateB, TimelineDateFormat)))
            .filter(([key, value]) => { 
                const foundedItems = (value as {founded: FoundedType}[])
                    .map((item) => ({...item.founded})).filter((item) => !!Object.keys(item).length);
                const metricItems = (value as {metrics: MetricsData}[])
                    .map((item) => ({...item.metrics}))
                    .filter((item) => !!Object.keys(item).length);
                const fundingItems = (value as {funding: FundingType}[])
                    .map((item) => ({...item.funding})).filter((item) => !!Object.keys(item).length);
                    
                if (!!foundedItems.length)
                    return true;

                mdHeaders.push(key);
                metricItems.forEach(metricData => {
                    const lookUpRow = rowsData.find(row => row[0].metric === metricData.metric) ?? [];

                    if (!!lookUpRow?.length) {
                        Array(mdHeaders.length - lookUpRow.length).fill({value: '-', date: undefined, units: undefined})
                            .forEach(emptyValues => lookUpRow.push({...metricData, ...emptyValues}));
                        lookUpRow[mdHeaders.length - 1] = {...metricData};
                    } else {
                        const row: MetricsData[] = [];

                        Array(mdHeaders.length).fill({value: '-', date: undefined, units: undefined})
                            .forEach(emptyValues => row.push({...metricData, ...emptyValues}));
                        row[mdHeaders.length - 1] = {...metricData};
                        rowsData.push(row);
                    }
                });

                const filteredMetricItems = metricItems.map(metricData => ({ ...metricData,
                    value: timelineView[0] === 'all' || timelineView.includes(metricData.metric)
                        ? metricData.value : '-'
                })).filter(({value}) => value !== '-');

                fundingItems.forEach(fundingData => fundingsData.push(fundingData));

                const priorFundings = fundingItems
                    .filter(({funding_type}) => !['fundraising', 'valuation'].join('|').includes(funding_type.toLowerCase()))
                    .map(({funding_type}) => funding_type);
                const filteredFundingItems = fundingItems.map(fundingData => ({ ...fundingData,
                    funding_type: timelineView[0] === 'all' 
                        || (timelineView.includes('Prior Funding') && priorFundings.join('|').includes(fundingData.funding_type))
                        || (timelineView.includes('Fundraising') && fundingData.funding_type.toLowerCase() === 'fundraising')
                        ||  (timelineView.includes('Valuation') && fundingData.funding_type.toLowerCase() === 'valuation')
                            ? fundingData.funding_type : '-'
                })).filter(({funding_type}) => funding_type !== '-');

                if (!!filteredFundingItems.length || !!filteredMetricItems.length) {
                    tlHeaders.push(key);

                    return true;
                }

                return false;
        }).map(([_, value]) => {
            const foundedItems = (value as {founded: FoundedType}[])
                .map((item) => ({...item.founded})).filter((item) => !!Object.keys(item).length);
            const metricItems = (value as {metrics: MetricsData}[])
                .map((item) => ({...item.metrics}))
                .filter((item) => !!Object.keys(item).length);
            const fundingItems = (value as {funding: FundingType}[])
                .map((item) => ({...item.funding})).filter((item) => !!Object.keys(item).length);

            const filteredMetricItems = metricItems.map(metricData => ({ ...metricData,
                value: timelineView[0] === 'all' || timelineView.includes(metricData.metric)
                    ? metricData.value : '-'
            })).filter(({value}) => value !== '-');

            const priorFundings = fundingItems
                .filter(({funding_type}) => !['fundraising', 'valuation'].join('|').includes(funding_type.toLowerCase()))
                .map(({funding_type}) => funding_type);
            const filteredFundingItems = fundingItems.map(fundingData => ({ ...fundingData,
                funding_type: timelineView[0] === 'all' 
                    || (timelineView.includes('Prior Funding') && priorFundings.join('|').includes(fundingData.funding_type))
                    || (timelineView.includes('Fundraising') && fundingData.funding_type.toLowerCase() === 'fundraising')
                    ||  (timelineView.includes('Valuation') && fundingData.funding_type.toLowerCase() === 'valuation')
                        ? fundingData.funding_type : '-'
            })).filter(({funding_type}) => funding_type !== '-');

            return {foundedItems, fundingItems: filteredFundingItems, metricItems: filteredMetricItems};
        });

        rowsData.forEach(row => {
            Array(mdHeaders.length - row.length).fill({value: '-', date: undefined, units: undefined})
                .forEach(emptyValues => row.push({...row[0], ...emptyValues}));
            mdHeaders.forEach((tlDate, i) => { row[i].date = moment(tlDate, TimelineDateFormat); });
        });

        setTimelineHeaders(tlHeaders);
        setModalHeaders(mdHeaders);
        setMetricsData(rowsData.filter(row => !row.every(({value}) => value === '-')));
        setFundingData(fundingsData); 
        setTimelineItems(tlItems);
    }, [timelineData]);

    useEffect(() => {
        if (!!queryMap) {
            const tlData: TimelineData = {};

            let hasFoundedYear = extractFoundedElement(tlData, queryMap['Founded']);
            extractMetricsElements(tlData, queryMap['Note Metrics']);
            extractFundingElements(tlData, queryMap['Funding']);

            setTimelineData(tlData);
            setWithFounded(hasFoundedYear);
        }
    // eslint-disable-next-line
    }, [queryMap]);

    useEffect(() => {
        if (!!Object.keys(timelineData).length)
            buildTimelineItems(timelineView);
    }, [buildTimelineItems, timelineView, timelineData]);

    useEffect(() => {
        if (storage.getItem('config.dashboard-metrics-timeline.expanded'))
            setExpanded(true);
    }, []);

    return (<>
        <Collapse in={isExpanded} collapsedSize={40}>
            <Stack direction="column" spacing={3} alignItems="stretch" width="100%" height="fit-content">
                <Stack direction="row" spacing={2} alignItems="flex-start" marginLeft={1}>
                    <Typography fontFamily="Inter" fontWeight="bold" fontSize="1.2rem" color={theme.colors.neutral['400']}>
                        {'Financial Timeline'}
                    </Typography>
                    <ExpandMoreIcon
                        className={classes.expandIcon}
                        sx={isExpanded ? { transform: 'rotate(180deg) !important' } : {}}
                        onClick={(e)=> {
                            e.stopPropagation();
                            e.preventDefault();
                            setExpanded((prev) => !prev);
                            storage.setItem('config.dashboard-metrics-timeline.expanded', !isExpanded);
                        }} />
                </Stack>
                {hasViewableElements ? (<>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" paddingX={3}>
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start" width="calc(100% - 200px)" overflow="hidden">
                            <Button variant="contained"
                                className={classNames(classes.viewsButton,
                                    timelineView.join('|').includes('all') && classes.selectedButton)}
                                onClick={() => handleTimelineView('all')}
                                sx={{ marginBottom: '4px' }}
                                disableRipple>{'Consolidated view'} </Button>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start" 
                                overflow="auto" width="calc(100% - 200px)" sx={{ ...scrollbarStyle}}>
                                {timelineViews.map((view, i) => (
                                    <Button variant="contained"
                                        className={classNames(classes.viewsButton,
                                            timelineView.join('|').includes(view) && classes.selectedButton)}
                                        onClick={() => handleTimelineView(view)}
                                        sx={{ minWidth: `calc(64px + ${8 * view.length}px)` }}
                                        key={'dashboard-metrics-timeline-v2-430-' + i}
                                        disableRipple> {view} </Button>
                                ))}  
                            </Stack>
                        </Stack>
                        {!isPublicView && (
                            <Button variant="outlined"
                                className={classes.addButton}
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={() => setAddMetricModalOpen(true)}
                                disableRipple>{'Add data'} </Button>
                        )}
                    </Stack>
                    <Stack width="100%" paddingX={3} overflow="hidden" paddingBottom={2}>
                        <Stack direction="row" spacing={4} alignItems="center" justifyContent="flex-start" 
                            overflow="auto" width="100%" sx={{ ...scrollbarStyle }}>
                            {metricViews.map((view, i) => (
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start" width="fit-content"
                                    key={'dashboard-metrics-timeline-v2-592-' + i}>
                                    <Typography className={classes.legendText}>
                                        {view}
                                    </Typography>
                                    <Chip className={classes.legendChip} 
                                        sx={{ background: metricColors.get(view.trim().toLowerCase()) }}/>
                                </Stack>
                            ))}  
                        </Stack>
                    </Stack>
                    <MetricsTimelineV2
                        elements={timelineElements}
                        dateHeader={timelineHeaders}
                        metricsData={filteredMetricsData}
                        unstyledStart={withFounded} />
                    <Box height="40px" />
                </>) : (
                    <Stack spacing={2} alignItems="center" justifyContent="center" width="100%" height="400px">
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%">
                            {!isPublicView && (
                                <Button variant="outlined"
                                    className={classes.addButton}
                                    startIcon={<AddCircleOutlineIcon />}
                                    onClick={() => setAddMetricModalOpen(true)}
                                    disableRipple>{'Add data'} </Button>
                            )}
                        </Stack>
                        <Stack spacing={2} alignItems="center" justifyContent="center" width="100%" height="400px">
                            <BarGraph width={120} height={120} fill={theme.colors.neutral['300']} />
                            <Typography className={classes.noText} mt={4}>{'No data yet.'}</Typography>
                            <Typography className={classes.otherText}>
                                {'Charts will appear when two or more'}<br/>
                                {'data points for the same metric.'}
                            </Typography>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Collapse>
        {addMetricModalOpen && (
            <FinancialTimelineMetricsModal
                isOpen={addMetricModalOpen}
                dashboard={dashboard!}
                dateHeader={modalHeaders}
                metricsData={metricsData}
                readOnly={isPublicView}
                onEdit={(customValues) => {
                    setCustomValues(customValues);
                    setAddCustomMetricsModalOpen(true);
                }}
                onCustomMetricsModalOpen={() => setAddCustomMetricsModalOpen(true)}
                onClose={() => setAddMetricModalOpen(false)} />
        )}
        {addCustomMetricsModalOpen && (
            <AddCustomMetricsModal
                isOpen={addCustomMetricsModalOpen}
                customValues={customValues}
                onClose={() => {
                    setCustomValues(undefined);
                    setAddCustomMetricsModalOpen(false);
                }} />
        )}
    </>);
}

export default DashboardMetricsTimelineV2;