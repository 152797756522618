import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { DataGrid, GridColDef, GridRowModes, GridRowModesModel, GridRowParams, GridRowsProp, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid';
import makeStyles from "@mui/styles/makeStyles";
import { Alert, AlertProps, Box, Button, Stack, Snackbar } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from "uuid";
import { createGroupFunc, getAllGroupSettingsFunc, } from "../../../lib/helper";
import { defineTextColumn } from "../../molecules/admin-menus/ColumnsDefinition";
import { GroupSettings } from "../../../API";
import { defaultStages, defaultTags } from "../../../shared/dashboard";

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

const useStyles = makeStyles((theme) => ({
    table: {
        border: 'none',
        maxHeight: 'calc(100vh - 150px)',
        "& .MuiDataGrid-withBorderColor": {
            borderColor: theme.colors.primary['300'],
        },
    },
    header: {
        fontFamily: 'Inter',
        fontWeight: 'bold',
        fontSize: '1.1rem',
        color: theme.colors.neutral['900'],
    },
    row: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        color: theme.colors.primary['700'],
        "& .MuiDataGrid-cell--editing > .MuiInputBase-root":{
            fontFamily: 'Inter',
            color: theme.colors.primary['700'],
            "& > input": {
                fontSize: '1rem',
            }
        }
    },
    addButton: {
        width: 150,
        height: 40,
        borderRadius: 32,
        borderWidth: 3,
        textTransform: 'none',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: '#048290',
        background: '#E0F1F1',
        "&:hover": {
            opacity: 0.8,
            background: '#E0F1F1',
        },
    },
}));

const GroupsTable: React.FC<{ onSelectGroup: Dispatch<SetStateAction<string|undefined>> }> = ({ onSelectGroup }) => {
    const classes = useStyles();
    const history = useHistory();
    const apiRef = useGridApiRef();

    const [rows, setRows] = useState<{id: string, group: string }[]>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const columns: GridColDef[] = useMemo(() => [
        defineTextColumn('group', 'Group name')
    ], []);

    const EditToolbar = useCallback((props: EditToolbarProps) => {
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
          const id = uuidv4();
          const newGroup = { id, group: '', isNew: true };

          setRows((oldRows) => [...oldRows, newGroup]);
          setRowModesModel((oldModel) => ({...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'group' },
          }));
        };

        return (
          <GridToolbarContainer>
            <Stack direction="row" justifyContent="space-between">
                <Button className={classes.addButton}
                    startIcon={<AddIcon />}
                    onClick={handleClick}> Add group </Button>
            </Stack>
          </GridToolbarContainer>
        );
    // eslint-disable-next-line
    }, []);

    const getAllGroupData = useCallback(async (): Promise<GroupSettings[]> => {
        const groupSettings = await getAllGroupSettingsFunc({});
        const results = groupSettings.items;
        let nextToken = groupSettings.nextToken;

        if (nextToken) {
            const nextGroupSettings = await getAllGroupSettingsFunc({ nextToken });
            results.push(...nextGroupSettings?.items);
            nextToken = nextGroupSettings?.nextToken;
        }

        return results;
    }, []);

    const handleSaveNewGroup = useCallback(async (updatedRow: { group: string, isNew: boolean }) => {
        setIsLoading(true);
        try {
            await createGroupFunc({
                group: updatedRow.group,
                statuses: [
                    'new',
                    'done',
                    'passed',
                ],
                investmentStages: defaultStages,
                tags: defaultTags,
                shouldSendDigestEmail: true,
            });
            updatedRow.isNew = false;
            setSnackbar({
                children: 'Group created successfully.',
                severity: 'success',
            });
        } catch(e: any) {
            setSnackbar({
                children: e?.errors[0]?.message || e.message || `There was an error creating the group. Please try again.`,
                severity: 'error',
            });
        } finally {
            setIsLoading(false);
        }

        return updatedRow;
    }, []);

    const handleProcessRowUpdateError = useCallback((error: Error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        getAllGroupData().then(res => {
            setRows(res.map((item) => {
                    return {
                        id: item.group,
                        group: item.group,
                    }
                }).sort((a, b) => a.group?.toLowerCase().localeCompare(b.group?.toLowerCase()))
            );
            setIsLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    return (<>
        <Box width="100%" height="100%">
            <DataGrid
                apiRef={apiRef}
                className={classes.table}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 25,
                        },
                    },
                }}
                loading={isLoading}
                columns={columns}
                rows={rows}
                rowModesModel={rowModesModel}
                getRowClassName={() => classes.row}
                isCellEditable={({row}) => row.isNew}
                editMode="row"
                processRowUpdate={(updatedRow) => handleSaveNewGroup(updatedRow)}
                onRowModesModelChange={handleRowModesModelChange}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                slots={{ toolbar: EditToolbar }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
                onRowClick={(item: GridRowParams<any>) => {
                    onSelectGroup(item.row.group);
                    history.push(`/groups/${item.row.group}`);
                }}
                pageSizeOptions={[25, 50, 100]}
            />
        </Box>
        <Snackbar
            open={Boolean(snackbar)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={() => setSnackbar(null)}
            autoHideDuration={6000}>
            <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
    </>);
}

export default GroupsTable;