import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {ReactComponent as CrunchBaseIcon} from "../../../assets/icons/crunchbase.svg";
import {ReactComponent as LinkedInIcon} from "../../../assets/icons/linkedin.svg";
import {ReactComponent as WorldGridIcon} from "../../../assets/icons/world.svg";
import AnswerMenu from './AnswerMenu';
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';
import { scrollbarStyle } from '../../../shared/dashboard';

const useStyles = makeStyles((theme) => ({
    answerContainer: {
        width: '100%',
        height: 'auto',
        marginLeft: 8,
        marginRight: 8,
        paddingLeft: 8,
        paddingRight: 8,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    answerHover: {
        width: '100%',
        '&:hover': {
            border: `1px ${theme.colors.neutral['400']} solid`,
            borderRadius: 12,
        }
    },
    answerBlock: {
        width: '100%',
        height: '48px',
        paddingTop: 8,
        paddingBottom: 8,
        color: '#666666',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    icon: {
        width: 24,
        height: 24,
        fill: '#7bd4d4',
    },
    url: {
        display: 'inline-block',
        maxWidth: '32ch',
        fontFamily: 'Inter',
        fontWeight: 700,
        textDecoration: 'none',
        color: theme.palette.common.black,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineClamp: 1,
        "&:hover": {
            textDecoration: 'none !important',
        },
    },
    linkOnlyUrl: {
        fontWeight: 600,
        color: theme.colors.neutral['700'],
    },
    notAvailable: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 400,
        color: theme.colors.neutral['500'],
    },
}));

const UrlRegex = /^(https?:\/\/)?(www\.)?([^\\/]+)(\/.*)?$/;
const LinkedInRegex = /linkedin\.com\/(company|showcase)\/([^\\/?]+)/;
const CrunchbaseRegex = /crunchbase\.com\/organization\/([^\\/?]+)/;

const AnswerHeaderLink: React.FC<{
    answeredQuestion: AnsweredQuestion,
    isEditingAnswer: boolean,
    isPublic?: boolean,
    linkOnly?: boolean,
    onEditingAnswer?: (state: boolean) => void,
    onEditAnswer?: (answer: string | null) => void,
    onDelete?: () => void,
}> = ({ answeredQuestion, isEditingAnswer, isPublic, linkOnly, onEditingAnswer, onEditAnswer, onDelete }) => {
    const classes = useStyles();
    const [hoverAnswer, setHoverAnswer] = useState<boolean>(false);

    const getIcon = (question: string | null) => {
        if (question) {
            if (question.toLowerCase().includes('crunchbase')) {
                return (<CrunchBaseIcon className={classes.icon} />);
            } else if (['linkedin', 'company', 'people'].reduce((found: boolean, keyword: string) => found || question.toLowerCase().includes(keyword), false)) {
                return (<LinkedInIcon className={classes.icon} />);
            } else {
                return (<WorldGridIcon className={classes.icon} stroke="#7bd4d4" />);
            }
        }
    }

    const getValidLink = useCallback(() => {
        if (!!answeredQuestion) {
            const url = answeredQuestion?.answer;

            if (isEditingAnswer) {
                return "#";
            } else if (!!url && typeof url === 'string') {
                if (!url.includes('https') && !url.includes('http'))
                    return `https://${url}`;

                return url;
            }
        }

        return "";
    }, [answeredQuestion, isEditingAnswer]);

    const getDisplayLink = useCallback(() => {
        const url = getValidLink().replace('https://', '').replace('http://', '');
        let display = answeredQuestion.question;

        if (linkOnly && !['', '#'].includes(url)) {
            if (LinkedInRegex.test(url)) {
                const matches = url.match(LinkedInRegex);

                display = !!matches ? `/${matches.pop()}` : display;
            } else if (CrunchbaseRegex.test(url)) {
                const matches = url.match(CrunchbaseRegex);

                display = !!matches ? `/${matches.pop()}` : display;
            } else if (UrlRegex.test(url)) {
                display = url.match(UrlRegex)?.[3] || display;
            }
        }

        return display;
    }, [answeredQuestion, getValidLink, linkOnly]);

    const handleClick = useCallback(() => {
        const url = isEditingAnswer ? '#' : answeredQuestion?.answer as string;

        if (!!url && url !== "#")
            window.open(url, '_blank', 'noopener, noreferrer');
    }, [answeredQuestion, isEditingAnswer]);

    useEffect(() => {
        if (!isEditingAnswer)
            setHoverAnswer(false);
    }, [isEditingAnswer]);

    return (<>
        <Stack direction="row" alignItems="center" justifyContent="space-between"
            px={linkOnly ? 0 : 2} width={!isEditingAnswer ? '100%': 'auto'}>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" overflow="hidden"
                width={!isEditingAnswer ? '100%': 'auto'}
                onClick={!answeredQuestion.answer ? (e) => {
                    e.stopPropagation();
                    onEditingAnswer?.(true);
                    onEditAnswer?.(answeredQuestion.answer! as string);
                } : undefined}
                onDoubleClick={!!answeredQuestion.answer ? (e) => {
                    e.stopPropagation();
                    onEditingAnswer?.(true);
                    onEditAnswer?.(answeredQuestion.answer! as string);
                } : undefined}>
                <IconButton onClick={handleClick} disableRipple>
                    {getIcon(answeredQuestion.question)}
                </IconButton>
                {!isEditingAnswer && (
                    <Stack direction="row" justifyContent="space-between"
                        className={classNames(classes.answerContainer, hoverAnswer && classes.answerHover)}
                        onMouseLeave={() => setHoverAnswer(false)}>
                        <Stack className={classes.answerBlock}
                            onMouseEnter={() => setHoverAnswer(isPublic === false)}
                            style={{ height: 'auto' }}>
                            {!!answeredQuestion.answer ? (
                                <Typography component="a"
                                    className={classNames(classes.url, linkOnly && classes.linkOnlyUrl)}
                                    href={getValidLink()}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {getDisplayLink()}
                                </Typography>
                            ) : (
                                <Typography className={classNames(classes.url, classes.notAvailable)}>
                                    {answeredQuestion.question?.toLowerCase()?.includes('linkedin') ? 'Add linkedin ...' : 'Add website ...'}
                                </Typography>
                            )}
                        </Stack>
                        <Stack direction="column" alignItems="center" justifyContent="center" width="40px">
                            {hoverAnswer && (
                                <IconButton size="small"
                                    className={classes.icon}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onEditingAnswer?.(true);
                                        onEditAnswer?.(answeredQuestion.answer! as string);
                                    }}
                                > <EditOutlinedIcon fontSize="small" /> </IconButton>
                            )}
                        </Stack>
                    </Stack>
                )}
            </Stack>
            {(!isEditingAnswer && !!answeredQuestion.answer) && (
                <Stack direction="row" alignItems="center">
                    <AnswerMenu
                        answeredQuestion={answeredQuestion}
                        displayMenu={!isPublic}
                        onSetIsEditingAnswer={() => onEditingAnswer?.(true)}
                        onSetEditAnswer={(answer: any) => onEditAnswer?.(answer as string)}
                        onDelete={onDelete}
                    />
                </Stack>
            )}
        </Stack>
    </>);
}

export default AnswerHeaderLink;
