import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dashboard } from "../../../types/files";
import { MetricsBaseWidth, MetricsData } from "../../atoms/MetricsTimelineV2";
import { RevenueSubcategories } from "../../molecules/dashboard-query-answer/MetricsAnswer";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: 'fit-content',
            maxWidth: '70vw',
            minWidth: '600px',
            height: 'fit-content',
            padding: 20,
            borderRadius: 32,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.5rem',
    },
    dialogContent: {
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        padding: '8px 0',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    text: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['500'],
    },
    container: {
        width: '100%',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        ...scrollbarStyle,
    },
    table: {
        width: '100%',
        display: 'inline-block',
        height: 'fit-content',
        transition: 'all 0.8s',
        willChange: 'transform',
    },
    column: {
        display: 'inline-block',
        fontSize: '1rem',
        padding: '40px 0 0',
    },
    header: {
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 'normal',
        textAlign: 'center',
        color: theme.colors.neutral['500'],
    },
    leftmostHeader: {
        fontWeight: 'bold',
        textAlign: 'left',
    },
    firstColumn: {
        justifyContent: 'flex-start',
        paddingLeft: '16px',
        borderLeft: `2px solid ${theme.colors.neutral['300']}`,
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px',
        color: theme.palette.primary.main,
    },
    midColumn: {
        justifyContent: 'center',
        color: 'black',
    },
    lastColumn: {
        justifyContent: 'center',
        borderRight: `2px solid ${theme.colors.neutral['300']}`,
        borderTopRightRadius: '12px',
        borderBottomRightRadius: '12px',
        color: 'black',
    },
    finalColumn: {
        borderRight: `2px solid ${theme.colors.neutral['300']}`,
        borderTopRightRadius: '12px',
        borderBottomRightRadius: '12px',
    },
    row: {
        height: '50px',
        alignItems: 'center',
        borderTop: `2px solid ${theme.colors.neutral['300']}`,
        borderBottom: `2px solid ${theme.colors.neutral['300']}`,
    },
    cell: {
        minWidth: '40px',
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    subtitle: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        color: theme.colors.neutral['600'],
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    icon: {
        width: 40,
        height: 40,
        padding: 'unset',
    },
    addIcon: {
        width: 'auto',
        height: 28,
        padding: 6,
        margin: 'unset',
        borderRadius: '50%',
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        "& > .MuiSvgIcon-root": {
          fontSize: 22,
          fill: theme.palette.primary.main,
        }
    },
    addSectionIcon: {
        width: 16,
        height: 'auto',
        stroke: theme.palette.primary.light,
    },
    addSectionButton: {
        minWidth: 100,
        width: 'auto',
        height: 40,
        borderRadius: 24,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    closeButton: {
        width: 100,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const FinancialTimelineMetricsModal: React.FC<{
    isOpen: boolean,
    dashboard: Dashboard,
    dateHeader: string[];
    metricsData: MetricsData[][];
    readOnly?: boolean;
    onEdit?: (customValues?: MetricsData) => void;
    onCustomMetricsModalOpen: () => void,
    onClose: () => void,
}> = ({ isOpen, dashboard, readOnly, metricsData, dateHeader, onEdit, onCustomMetricsModalOpen, onClose }) => {
    const classes = useStyles();
    const [hoverIndex, setHoverIndex] = useState<number[]|undefined>(undefined);
    const tableRef0 = useRef<HTMLElement|null>(null);
    const tableRef1 = useRef<HTMLElement|null>(null);
    const winWidth = useRef<number>(window.innerWidth);
    const resizeTimer = useRef<number>(0);

    const fundraisingRound = metricsData.find(funding => funding.every(({metric}) => metric.toLowerCase() === 'fundraising'));
    const valuationRound = metricsData.find(funding => funding.every(({metric}) => metric.toLowerCase() === 'valuation'));

    const fundingRounds = useMemo(() => [
        fundraisingRound ?? [{metric: 'Fundraising', value: '-'}] as MetricsData[],
        valuationRound ?? [{metric: 'Valuation', value: '-'}] as MetricsData[],
    ] , [fundraisingRound, valuationRound]);

    const metricsOnly = useMemo(() => metricsData.filter(row =>
        !['fundraising', 'valuation'].join('|').includes(row[0].metric.toLowerCase()))
    , [metricsData]);

    const fundingRoundsIterator = useMemo(() => Array
        .from({ length: fundingRounds.reduce((acc, row) => Math.max(acc, row.length), 0) }, (_, i) => i + 1)
    , [fundingRounds]);

    const setUpTimeline = useCallback((tableRef: MutableRefObject<(HTMLElement | null)>) => {
        const scroller = tableRef.current as HTMLElement;
        const items = Array.from(scroller?.children ?? []) as HTMLElement[];
        let maxHeight = 0;

        items!.forEach((item: HTMLElement, i) => {
          item.removeAttribute("style");
          item.style.width = `${(i === 0 ? 1.5 : 1) * MetricsBaseWidth}px`;
          maxHeight = Math.max(maxHeight, item.offsetHeight);
          item.style.opacity = "0";
        });
        items!.forEach((item: HTMLElement) => {
          item.style.height = `${maxHeight}px`;
          item.style.opacity = "1";
        });
    }, []);

    const hasValidEntry = useCallback((index) =>
        (index === 0) || !metricsData.every(row => row[index].value === '-')
    , [metricsData]);

    const hasLastValidEntry = useCallback((index, iterator: number[]) =>
        (index === (iterator.length - 1) && hasValidEntry(index))
        || ((index === (iterator.length - 2)) && !hasValidEntry(index + 1))
    , [hasValidEntry]);

    useEffect(() => {
        resizeTimer.current = window.setTimeout(() => {
            winWidth.current = window.innerWidth;

            if (!!metricsData.length){
                setUpTimeline(tableRef0);
                setUpTimeline(tableRef1);
            }

            clearTimeout(resizeTimer.current);
        }, 300);
    // eslint-disable-next-line
    }, [metricsData]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>
                {'Financial Timeline Metrics'}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Typography className={classes.text}>
                    {`Add and/or edit metrics for ${dashboard.title}: `}
                </Typography>
                <Stack className={classes.container} marginBottom={2}>
                    <Box className={classes.table} ref={tableRef0} >
                        {fundingRoundsIterator.map((_, index, iterator) => hasValidEntry(index) && (
                            <Box className={classes.column} key={'financial-timeline-metrics-modal-319-' + index}>
                                <Stack direction="column" spacing={1} alignItems="stretch">
                                    <Typography className={classnames(classes.header, (index === 0) && classes.leftmostHeader)}>
                                        {index === 0 ? 'Funding Rounds' : dateHeader[index]}
                                    </Typography>
                                    {fundingRounds.map((row, rIndex, self)=> (
                                        <Stack direction="row" spacing={1}
                                            className={classnames((index === 0) ? classes.firstColumn
                                                : hasLastValidEntry(index, iterator) ? classes.lastColumn
                                                : classes.midColumn,
                                                (self.every(r => r.length === 1)) && classes.finalColumn
                                                , classes.row)}
                                            onMouseLeave={() => setHoverIndex(undefined)}
                                            key={'financial-timeline-metrics-modal-326-' + index + '-' + rIndex}>
                                            {(index === 0) ? (
                                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                                    {RevenueSubcategories.map(cat => cat.toLowerCase()).includes(row[index].metric.trim().toLowerCase()) ? (
                                                        <Typography className={classes.cell}>
                                                            {'Revenue '}
                                                            <Typography component="span" className={classes.subtitle} display="inline"> ({row[index].metric})</Typography>
                                                        </Typography>
                                                    ): (
                                                        <Typography className={classes.cell}>
                                                            {row[index].metric}
                                                        </Typography>
                                                    )}
                                                    {!readOnly && (
                                                        <IconButton size="small"
                                                            className={classes.icon}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onEdit?.([...self][rIndex][0]);
                                                            }} disableRipple
                                                        > <AddIcon className={classes.addIcon} fontSize="small" /> </IconButton>
                                                    )}
                                                </Stack>
                                            ) : (
                                                <Typography component={Box} className={classes.cell}
                                                    onMouseEnter={() => setHoverIndex((index !== 0) ? [rIndex, index, 0] : undefined)}>
                                                    {row[index]?.value || '-'}
                                                </Typography>
                                            )}
                                            {!readOnly && (hoverIndex?.[0] === rIndex && hoverIndex?.[1] === index && hoverIndex?.[2] === 0) ? (
                                                <IconButton size="small"
                                                    className={classes.icon}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onEdit?.([...self][rIndex][index]);
                                                    }}
                                                > <EditOutlinedIcon fontSize="small" /> </IconButton>
                                            ) : (<Box />)}
                                        </Stack>
                                    ))}
                                </Stack>
                            </Box>
                        ))}
                    </Box>
                    <Box className={classes.table} ref={tableRef1}>
                        {!!metricsOnly?.length ? (<>
                            {Array.from({ length: metricsOnly[0]?.length }, (_, i) => i + 1).map((_, index, iterator) => hasValidEntry(index) && (
                                <Box className={classes.column} key={'financial-timeline-metrics-modal-319-' + index}>
                                    <Stack direction="column" spacing={1} alignItems="stretch">
                                        <Typography className={classnames(classes.header, (index === 0) && classes.leftmostHeader)}>
                                            {dateHeader[index]}
                                        </Typography>
                                        {metricsOnly.map((row, rIndex, self)=> (
                                            <Stack direction="row" spacing={1}
                                                className={classnames((index === 0) ? classes.firstColumn
                                                    : hasLastValidEntry(index, iterator) ? classes.lastColumn
                                                    : classes.midColumn, classes.row)}
                                                onMouseLeave={() => setHoverIndex(undefined)}
                                                key={'financial-timeline-metrics-modal-326-' + index + '-' + rIndex}>
                                                {(index === 0) ? (
                                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                                        {RevenueSubcategories.map(cat => cat.toLowerCase()).includes(row[index].metric.trim().toLowerCase()) ? (
                                                            <Typography className={classes.cell}>
                                                                {'Revenue '}
                                                                <Typography component="span" className={classes.subtitle} display="inline"> ({row[index].metric})</Typography>
                                                            </Typography>
                                                        ): (
                                                            <Typography className={classes.cell}>
                                                                {row[index].metric}
                                                            </Typography>
                                                        )}
                                                        {!readOnly && (
                                                            <IconButton size="small"
                                                                className={classes.icon}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onEdit?.([...self][rIndex][0]);
                                                                }} disableRipple
                                                            > <AddIcon className={classes.addIcon} fontSize="small" /> </IconButton>
                                                        )}
                                                    </Stack>
                                                ) : (
                                                    <Typography component={Box} className={classes.cell}
                                                        onMouseEnter={() => setHoverIndex((index !== 0) ? [rIndex, index, 1] : undefined)}>
                                                        {row[index].value}
                                                    </Typography>
                                                )}
                                                {!readOnly && (hoverIndex?.[0] === rIndex && hoverIndex?.[1] === index && hoverIndex?.[2] === 1) ? (
                                                    <IconButton size="small"
                                                        className={classes.icon}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onEdit?.([...self][rIndex][index]);
                                                        }}
                                                    > <EditOutlinedIcon fontSize="small" /> </IconButton>
                                                ) : (<Box />)}
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Box>
                            ))}
                        </>) : (<>
                            <Box className={classes.column}>
                                <Stack direction="column" spacing={1} alignItems="stretch">
                                    <Typography className={classnames(classes.header, classes.leftmostHeader)}>
                                        {'Metrics'}
                                    </Typography>
                                </Stack>
                            </Box>
                        </>)}
                    </Box>
                </Stack>
                {!readOnly && (
                    <Stack alignItems="flex-start" width="100%">
                        <Button variant="outlined"
                            className={classes.addSectionButton}
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={() => onCustomMetricsModalOpen()}>
                            {'Add new metrics'}
                        </Button>
                    </Stack>
                )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%">
                    <Button className={classes.closeButton}
                        onClick={onClose}> Close </Button>
                    </Stack>
            </DialogActions>
        </Dialog>
    </>);
}

export default FinancialTimelineMetricsModal;