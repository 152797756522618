import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DashboardsContext, OverviewQueryTitles } from "../../../contexts/DashboardsContext";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dashboard, DashboardQuery, Workspace } from "../../../types/files";
import { FallbackLoading } from "../../templates/loader";
import NoDashboardsFoundView from "../dashboards-empty/NoDashboardsFoundView";
import useDashboards from "../../../hooks/useDashboards";
import moment from "moment";
import DashboardsTableRow from "../dashboards-table/DashboardsTableRow";
import DashboardsTableHeader from "../dashboards-table/DashboardsTableHeader";
import theme from "../../../theme";
import { collectionKey } from "../../organisms/dashboards/DashboardsView";
import useBackwardsCompatible from "../../../hooks/useBackwardsCompatible";
import { updateWorkspaceFunc, refreshWorkspaceScoreFunc, getGroupDashboardQueriesFunc } from "../../../lib/helper";
import {DashboardQueriesContext} from "../../../contexts/DashboardQueriesContext";
import { AuthContext } from "../../../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
    container: {
        height: 'calc(100% - 30px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: 20,
        ...scrollbarStyle,
    },
    table: {
        width: '100%',
        height: 'calc(100vh - 200px)',
        overflow: 'hidden',
        marginLeft: 12,
    },
    headers: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        ...scrollbarStyle,
    },
    rows: {
        minWidth: '100%',
        width: 'fit-content',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    section: {
        width: '100%',
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 600,
        color: '#3ABDC6',
        "&::before, &::after": {
            borderTop: 'thin solid #3ABDC6',
        }
    },
    loadingText: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 700,
        color: theme.colors.neutral['900'],
    },
    loadingTextSub: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: '#9e9e9e',
    },
    loading: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.main,
    },
    sharedTitle: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: '600',
        color: theme.colors.neutral['700'],
    },
}));

export const TableHeaders = ['Name', 'Fit score', 'Stage', 'Sectors', 'Location', '', 'Added', ''];

const SharedByMe: React.FC<{
    workspace?: Workspace,
    search?: string,
 }> = ({ workspace, search }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { mappedOverviewQueries, workspace: globalWorkspace, dashboards, isPublicView,
        setWorkspace, setDashboardsQueries } = useContext(DashboardsContext);
    const { defaultQueryTemplateValues } = useContext(DashboardQueriesContext);
    const { filterDashboardList } = useDashboards();
    const { dashboardWorkspaces } = useBackwardsCompatible();

    const [loading, setLoading] = useState<boolean>(false);
    const [searching, setSearching] = useState<boolean>(false);
    const [dashboardArray, setDashboardArray] = useState<Dashboard[]>([]);
    const [sortBy, setSortBy] = useState<string>('Added');
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);

    const getMembership = useCallback((dashboard: Dashboard) =>
        dashboard?.workspaceMemberships?.find(wsMem => wsMem.workspaceId === localWorkspace?.id)
    , [localWorkspace?.id]);

    const handleRefresh = useCallback((refresh?: boolean) => {
        if (!!localWorkspace) {
            const promises: Promise<any>[] = [];
            const queryDefaults = defaultQueryTemplateValues.find(defQuery => defQuery?.title === 'Investment Thesis');

            if (refresh)
                updateWorkspaceFunc(localWorkspace).then((updatedWorkspace?: Workspace) => setWorkspace({...updatedWorkspace!}));

            dashboards.forEach(dashboard => promises.push(
                refreshWorkspaceScoreFunc({
                    dashboardId: dashboard.id,
                    workspaceId: localWorkspace.id,
                    group: userGroup,
                    query: queryDefaults?.queryTemplate.replace('{dashboard_name}', dashboard.title) || '{dashboard_name}',
                })
            ));

            Promise.all(promises).then(() => {
                setSearching(true);
            });
        }
    // eslint-disable-next-line
    }, [dashboards, userGroup, localWorkspace]);

    const sortedDashboards = useMemo(() => {
        if (sortBy === 'Fit score') {
            return Array.from(new Map(
                [...dashboardArray].filter(dashboard => dashboardWorkspaces(dashboard)?.includes(localWorkspace!.id)).map(dashboard => {
                    const investmentThesis: { workspaceId: string, score: number}[]
                        = mappedOverviewQueries.get(`${dashboard.id}:Investment Thesis`) || [];
                    const score = investmentThesis?.find(iT => iT.workspaceId === localWorkspace!.id)?.score ?? 0;

                    return [dashboard, score];
                }))).sort((a, b) => b[1] - a[1]).map(entry => entry[0]);
        }

        // else sort by 'Added'
        return [...dashboardArray].sort((prev: Dashboard, next: Dashboard) => {
            const prevDate = getMembership(prev)?.updatedAt || prev.createdAt;
            const nextDate = getMembership(next)?.updatedAt || next.createdAt;

            return nextDate.localeCompare(prevDate);
        });
    // eslint-disable-next-line
    }, [dashboardArray, sortBy]);

    const latelySharedArray = useMemo(() => sortedDashboards.filter(dashboard => {
        const wsMembership = getMembership(dashboard);

        return moment().diff(!!wsMembership ? wsMembership.updatedAt : dashboard.updatedAt, 'days') < 7;
    }), [sortedDashboards, getMembership]);

    const previouslySharedArray = useMemo(() => sortedDashboards.filter(dashboard => {
        const wsMembership = getMembership(dashboard);

        return moment().diff(!!wsMembership ? wsMembership.updatedAt : dashboard.updatedAt, 'days') >= 7;
    }), [sortedDashboards, getMembership]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setDashboardArray(filterDashboardList(search || '', localWorkspace?.id));
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    }, [filterDashboardList, localWorkspace?.id, search]);

    useEffect(() => {
        if (searching) {
            timerRef.current = setTimeout(() => {
                clearInterval(timerRef.current);
                getGroupDashboardQueriesFunc(userGroup).then((dashboardQueriesData) => {
                    setDashboardsQueries(dashboardQueriesData.filter((query: DashboardQuery) =>
                        OverviewQueryTitles.includes(query.title)
                    ));
                    setSearching(false);
                    setSortBy('Fit score');
                });
                timerRef.current = setTimeout(() => {
                    clearInterval(timerRef.current);
                    setSearching(false);
                    setSortBy('Fit score');
                }, 45000);
            }, 15000);
        }

        return () => {
            clearInterval(timerRef.current);
        }
    // eslint-disable-next-line
    }, [searching]);

    useEffect(() => {
        setLoading(!!timerRef.current && !dashboardArray && !search && !searching);
    }, [dashboardArray, search, searching]);

    if (!localWorkspace)
        return (<></>);

    return (<>
        {loading ? (<FallbackLoading />) : (<>
            {isPublicView && (<>
                <Typography className={classes.sharedTitle} pl={1} py={1}>
                    {`Deals shared:`}
                </Typography>
                <Divider sx={{ width: '100%' }} />
            </>)}
            <Stack className={classes.table}>
                {loading ? (<FallbackLoading />) : (searching) ? (
                    <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
                        <Typography className={classes.loadingText}>
                            {`Finding top matches for ${localWorkspace!.name}`}
                        </Typography>
                        <Stack alignItems="center" justifyContent="center" mt={2} mb={6}>
                            <Typography className={classes.loadingTextSub}>
                                {'All scores may take up to a minute to calculate'}
                            </Typography>
                        </Stack>
                        <CircularProgress className={classes.loading} size={60} thickness={3} />
                    </Stack>
                ) : (<>
                    {(!!latelySharedArray.length || !!previouslySharedArray.length) ? (<>
                        <Stack className={classes.headers} alignItems="flex-start" justifyContent="flex-start">
                            <DashboardsTableHeader
                                columns={TableHeaders}
                                columnSorted={sortBy}
                                onColumnSort={setSortBy}
                                onRefreshScores={() => handleRefresh()}
                                actions={['Remove from workspace']}
                                xAdjust={80}
                                multiAction />
                            <Stack className={classes.rows} alignItems="flex-start" justifyContent="flex-start">
                                {latelySharedArray?.map(dashboard => (
                                    <DashboardsTableRow
                                        dashboard={dashboard}
                                        workspace={workspace ?? undefined}
                                        columns={TableHeaders}
                                        collectionKey={collectionKey}
                                        timestamp={getMembership(dashboard)?.updatedAt}
                                        isPublic
                                        key={'saved-matches-150-' + dashboard.id} />
                                ))}
                                {!!previouslySharedArray.length && (
                                    <Stack direction="row" alignItems="center" justifyContent="stretch" width="100%" minHeight="80px">
                                        <Divider className={classes.section}>{'Previously shared'}</Divider>
                                    </Stack>
                                )}
                                <Box borderBottom={`1px solid ${theme.colors.neutral['400']}`} width="100%" />
                                {previouslySharedArray?.map(dashboard => (
                                    <DashboardsTableRow
                                        dashboard={dashboard}
                                        workspace={workspace ?? undefined}
                                        columns={TableHeaders}
                                        collectionKey={collectionKey}
                                        timestamp={getMembership(dashboard)?.updatedAt}
                                        prevShared isPublic
                                        key={'saved-matches-172-' + dashboard.id} />
                                ))}
                            </Stack>
                        </Stack>
                    </>) : (<NoDashboardsFoundView noResult isPublic={isPublicView} fullWidth />)}
                </>)}
            </Stack>
        </>)}
    </>);
}

export default SharedByMe;