import { Button, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { collectionKey } from "../../pages/InvestorsPage";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import WorkspacesTableHeader, { TableHeaders } from "../../molecules/workspaces-table/WorkspacesTableHeader";
import WorkspacesTableRow from "../../molecules/workspaces-table/WorkspacesTableRow";
import NoWorkspacesFoundView from "../../molecules/workspaces-empty/NoWorkspacesFoundView";
import { FallbackLoading } from "../../templates/loader";
import { Workspace } from "../../../types/files";
import { scrollbarStyle } from "../../../shared/dashboard";
import CreateEditInvestorModal from "../investors/CreateEditInvestorModal";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            minWidth: 1200,
            maxHeight: 600,
            width: 'min-content',
            height: 'fit-content',
            padding: 20,
            borderRadius: 16,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.5rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    selected :{
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1rem',
        color: 'black',
    },
    table: {
        width: '100%',
        height: 'calc(100vh - 120px)',
        overflow: 'hidden',
    },
    headers: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        ...scrollbarStyle,
    },
    rows: {
        minWidth: '100%',
        width: 'fit-content',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    createButton: {
        width: 'auto',
        height: 40,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    cancelButton: {
        width: 100,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    saveButton: {
        width: 100,
        borderRadius: 20,
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const AddToInvestorsModal: React.FC<{
    isOpen: boolean,
    loading?: boolean,
    onAdd?: (workspaceIds: string[]) => void,
    onClose: () => void,
}> = ({ isOpen, loading, onAdd, onClose }) => {
    const classes = useStyles();
    const { workspaces } = useContext(GroupSettingsContext);
    const { getBulkWorkspaces, clearBulk} = useBulkWorkspaces();

    const [localLoading, setLocalLoading] = useState<boolean>(false);
    const [createWorkspaceModalOpen, setCreateWorkspaceModalOpen] = useState<boolean>(false);
    const [investors, setInvestors] = useState<Workspace[]>([]);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const bulkCount = useMemo(() => getBulkWorkspaces(collectionKey).length, [getBulkWorkspaces]);

    const workspaceIds = useMemo(() =>
        getBulkWorkspaces(collectionKey)?.map(ws => ws.id) ?? [],
    [getBulkWorkspaces]);

    useEffect(() => {
        setLocalLoading(false);
        clearBulk(collectionKey);
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setInvestors(workspaces);
            setLocalLoading(false);
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    }, [workspaces]);

    useEffect(() => {
        setLocalLoading(!!timerRef.current && !investors.length);
    }, [investors.length]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                    <span>{'Add to investor workspaces'}</span>
                    <Button variant="outlined"
                        className={classes.createButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setCreateWorkspaceModalOpen(true);
                        }}
                    >{'+  New person'} </Button>
                </Stack>
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Stack className={classes.table}>
                    {localLoading ? (<FallbackLoading />) : !!investors.length ? (<>
                        <Stack className={classes.headers} alignItems="flex-start" justifyContent="flex-start">
                            <WorkspacesTableHeader columns={TableHeaders}  />
                            <Stack className={classes.rows} alignItems="flex-start" justifyContent="flex-start">
                                {investors.map(investor => (
                                    <WorkspacesTableRow
                                        workspace={investor}
                                        collectionKey={collectionKey}
                                        forSharing
                                        key={'add-to-investors-modal-126-' + investor.id} />
                                ))}
                            </Stack>
                        </Stack>
                    </>) : (
                        <Stack className={classes.headers} alignItems="flex-start" justifyContent="flex-start">
                            <WorkspacesTableHeader columns={TableHeaders} />
                            <Stack className={classes.rows} alignItems="flex-start" justifyContent="flex-start">
                                <NoWorkspacesFoundView noResult />
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%">
                    <Typography className={classes.selected}>{`${bulkCount} investors selected`}</Typography>
                    <Button className={classes.cancelButton}
                        onClick={onClose}> {'Cancel'} </Button>
                    <LoadingButton variant="contained"
                        className={classes.saveButton}
                        loading={loading}
                        onClick={() => onAdd?.(workspaceIds)}
                    > {'Add'} </LoadingButton>
                </Stack>
            </DialogActions>
        </Dialog>
        {createWorkspaceModalOpen && (
            <CreateEditInvestorModal
                isOpen={createWorkspaceModalOpen}
                onClose={() => {
                    setCreateWorkspaceModalOpen(false);
                }}
            />
        )}
    </>);
}

export default AddToInvestorsModal;