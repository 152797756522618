import { FC, useState, useContext, useRef, useCallback, useMemo } from "react";
import { ClickAwayListener, MenuItem, Popper, Stack } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import StatusBadge from "../status/StatusBadge";
import { updateDashboardFunc } from "../../../lib/helper";
import { Dashboard } from "../../../types/files";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    vertical: {
        marginLeft: '8px !important',
        marginTop: '-42px !important',
    },
    horizontal: {
        marginLeft: '8px !important',
        marginTop: -42,
    },
    container: {
        width: 'auto',
        height: 'fit-content',
        paddingTop: 8,
        paddingBottom: 8,
        borderRadius: 16,
        backgroundColor: '#fff',
        border: `1px solid ${theme.colors.neutral['400']}`,
        boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
        transition: '0.05s ease-in-out',
    },
    horizontalWrapper: {
        marginLeft: 8,
        marginTop: -42,
    },
    label: {
        cursor: 'normal',
        color: 'black !important',
        fontSize: '0.9rem',
        opacity: '0.5 !important',
    },
    menu: {
        width: '100%',
    },
    horizontalMenu: {
        width: '100%',
        paddingLeft: 6,
        paddingRight: 6,
        "&:hover": {
            background: 'unset !important',
        }
    }
}));

const DashboardStatusMenu: FC<{
    dashboard: Dashboard;
    readOnly?: boolean,
    popUpOnly?: boolean,
    horizontal?: boolean,
    globalHoverAnchor?: HTMLElement|null,
    onStatusChanged?: (newStatus: string) => void,
    onHoverAnchor?: (anchor: HTMLElement|null) => void,
}> = ({ dashboard, readOnly, popUpOnly, horizontal, globalHoverAnchor, onStatusChanged, onHoverAnchor }) => {
    const classes = useStyles();
    const { statuses } = useContext(GroupSettingsContext);
    const [localHoverAnchor, setLocalHoverAnchor] = useState<HTMLElement|null>(null);
    const anchorRef = useRef<HTMLElement>(null);

    const xAdjust = useMemo(() =>
        dashboard?.status?.length > 24 ? 5.5 * (24 - dashboard?.status?.length) : 0
    , [dashboard?.status?.length]);

    const hoverAnchor = useMemo(() =>
        popUpOnly ? globalHoverAnchor : localHoverAnchor
    , [globalHoverAnchor, localHoverAnchor, popUpOnly]);

    const setStatus = useCallback(async (item: string) => {
        await updateDashboardFunc({ ...dashboard,
            status: item === dashboard.status ? '<rename this status>' : item,
        });

        if (onStatusChanged)
            onStatusChanged(item);
    }, [dashboard, onStatusChanged]);


    const handleHoverAnchor = useCallback((anchor: HTMLElement|null) => {
        if (!!onHoverAnchor)
            onHoverAnchor?.(anchor);
        else
            setLocalHoverAnchor(anchor);
    }, [onHoverAnchor]);

    return (<>
        {!popUpOnly && (
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" ref={anchorRef}
                onMouseEnter={() => handleHoverAnchor(anchorRef.current)} sx={{ cursor: 'pointer' }}>
                <StatusBadge title={dashboard.status || 'N/A'} />
            </Stack>
        )}
        {!readOnly && (<>
            <Popper open={Boolean(hoverAnchor)} anchorEl={hoverAnchor} placement="right-start"
                className={classNames(horizontal ? classes.horizontal : classes.vertical)}>
                <ClickAwayListener onClickAway={() => handleHoverAnchor(null)}>
                    <Stack className={classNames(classes.container, horizontal && classes.horizontalWrapper)}
                        sx={{ transform: `translateX(${xAdjust}px)` }}
                        onMouseLeave={() => handleHoverAnchor(null)}>
                        <MenuItem className={classes.label} disabled
                            sx={{ paddingTop: '0 !important' }}
                            key={'dashboard-status-menu-98-top'}>
                            {'Move to:'}
                        </MenuItem>
                        <Stack direction={horizontal ? 'row' : 'column'} alignItems="flex-start" width={horizontal ? 'auto' : '100%'} px="4px">
                            {statuses.map((item, i) => (
                                <MenuItem className={classNames(classes.menu, horizontal && classes.horizontalMenu)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setStatus(item || '');
                                        setLocalHoverAnchor(null);
                                    }} key={'dashboard-status-menu-70-' + i}>
                                        <StatusBadge title={item} />
                                </MenuItem>
                            ))}
                        </Stack>
                    </Stack>
                </ClickAwayListener>
            </Popper>
        </>)}
    </>);
}

export default DashboardStatusMenu;