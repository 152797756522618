import { Box, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MetricsBaseWidth, MetricsMaxItemHeight } from "../../atoms/MetricsTimelineV2";

const useStyles = makeStyles(() => ({
    bar: {
        position: 'relative',
        display: 'block',
        padding: '0px 4px',
    }

}));

const MetricsElement: React.FC<{
    items: {metric: string, value: string}[],
    metricColors: Map<string, string>,
    maxMetricValue: number,
}> = ({items, metricColors, maxMetricValue}) => {
    const classes = useStyles();
    const [graphEntries, setGraphEntries] = useState<(({ value: string, width: number, height: number, background: string}))[]>([]);

    const numericItems = useMemo(() => items.filter(({value}) => !!value && (value !== '-') && value.includes('$')), [items]);
    const baseWidth = useMemo(() => (numericItems.length < 3) ? 60  : (numericItems.length < 5) ? 50  
        : (MetricsBaseWidth / (numericItems.length + 1)), [numericItems]);

    const formatToDollar = useCallback((value: string) => {
        const num = parseFloat(value.replace(/[^0-9.-]/g, ""));

        if (isNaN(num))
            return "$0";

        if (num >= 1_000_000_000)
            return `$${(num / 1_000_000_000).toFixed(1)}B`;

        if (num >= 1_000_000)
            return `$${(num / 1_000_000).toFixed(1)}M`;

        if (num >= 1_000)
            return `$${(num / 1_000).toFixed(1)}K`;

        return `$${num.toFixed(2)}`;
    }, []);

    const getNormalHeight = useCallback((value: string) => {
        const numericValue = parseFloat(value.replace(/[^0-9.-]/g, ""));
    
        if (numericValue <= 0)
            return 0;

        if (maxMetricValue === 0)
            return MetricsMaxItemHeight - 40;
    
        return (numericValue / maxMetricValue) * MetricsMaxItemHeight; 
    }, [maxMetricValue]);

    const adjustFontSize = useCallback((value: string) => {
        const minFontSize = 8;
        let maxFontSize = 12;
        let textWidth = value.length * maxFontSize * 0.6;

        while (textWidth > (baseWidth - minFontSize) && maxFontSize > minFontSize) {
            maxFontSize--;
            textWidth = value.length * maxFontSize * 0.6;
        }

        return maxFontSize;
    }, [baseWidth]);

    useEffect(() => {
        setGraphEntries(items
            .filter(({value}) => !!value && (value !== '-') && value.includes('$'))
            .filter(({metric}) => !['fundraising', 'valuation'].join('|').includes(metric.toLowerCase()))
            .map(({metric, value}) => ({
                value: formatToDollar(value),
                width: baseWidth,
                height: getNormalHeight(value) > 0 ? getNormalHeight(value) : 5,
                background: metricColors.get(metric.trim().toLowerCase())!,
            }))
        );
    // eslint-disable-next-line
    }, [items]);

    return (<>
        <Stack direction="column" spacing={1} alignItems="flex-end" >
            <Stack direction="row" spacing="1px" alignItems="flex-end" justifyContent="center" >
                {graphEntries.map(({value, width, height, background}, i) => (
                    <Stack direction="column" spacing={1} alignItems="center" key={'dashboard-timeline-410-' + i}>
                        <Typography fontFamily="Inter" fontWeight="bold" color="#666666"
                            sx={{ fontSize: adjustFontSize(value) }}> {value} </Typography>
                        <Box className={classes.bar} sx={{ width, height, background }}/>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    </>);
}

export default MetricsElement;