import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { TabContext, TabPanel } from "@mui/lab";
import { Box, ClickAwayListener, Divider, FormControl, IconButton, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import useDashboards from "../../../hooks/useDashboards";
import useRoute from "../../../hooks/useRoute";
import { updateInvestmentThesisFunc } from "../../../lib/helper";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dashboard, InvestmentThesis as InvestmentThesisType, Workspace } from "../../../types/files";
import Preferences from "../../molecules/workspaces/Preferences";
import SharedByDealOwner from "../../molecules/workspaces/SharedByDealOwner";
import SharedByMe from "../../molecules/workspaces/SharedByMe";
import { FallbackLoading } from "../../templates/loader";

export enum SharedBy {
    DealOwner = 'Deal Owner',
    Me = 'Me',
}

const useStyles = makeStyles((theme) => ({
    panel: {
        height: '100%',
        width: '100%',
        minWidth: 'calc(100vw - 94px - 550px)',
        maxWidth: 'calc(100vw - 94px - 135px)',
        transition: 'width .3s ease',
        overflow: 'hidden',
    },
    expanded: {
        height: '100%',
        width: '100%',
        minWidth: 'calc(100vw - 94px - 156px)',
        maxWidth: '100%',
    },
    preferences: {
        overflow: 'hidden',
        zIndex: 1300,
    },
    tabPanel: {
        height: '100%',
        width: '100%',
        minWidth: 'calc(100vw - 94px - 550px)',
        maxWidth: 'calc(100vw - 94px - 550px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: 'unset',
        ...scrollbarStyle,
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.75rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    selectorForm: {
        minWidth: 'unset',
        width: 'fit-content',
        height: 'auto',
        padding: 0,
        border: 0,
        "& > .MuiInputBase-root":{
            "& > .MuiSelect-select": {
                paddingLeft: 6,
            },
            "& > .MuiSvgIcon-root": {
                width: 24,
                height: 24,
                fill: theme.colors.neutral['600'],
                '&.Mui-disabled': {
                    fill: 'rgba(0, 0, 0, 0.26)',
                },
            },
            "& fieldset": {
              border: 0,
            },
        },
    },
    menuItem: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 8,
    },
    menuLabel: {
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 600,
        color: theme.colors.neutral['600'],
    },
    menuSelected: {
        fontFamily: 'Inter',
        fontWeight: 700,
        color: theme.colors.primary['400'],
    },
}));

const InvestmentThesis: React.FC<{
    workspace?: Workspace,
    search?: string,
}> = ({ workspace, search }) => {
    const classes = useStyles();
     const { userGroup } = useContext(AuthContext);
    const { setWorkspaces } = useContext(GroupSettingsContext);
    const { isPublicView, workspace: globalWorkspace, setWorkspace } = useContext(DashboardsContext);
    const { dashboardsLoaded, filterDashboardList } = useDashboards();
    const { getURLFrom } = useRoute();

    const [expanded, setExpanded] = useState<boolean>(false);
    const [selected, setSelected] = useState<SharedBy>(SharedBy.DealOwner);
    const [dashboardArray, setDashboardArray] = useState<Dashboard[]>([]);
    const hasSharedByMeCheck = useRef<boolean>(false);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const boxShadow = '-8px 8px 12px rgba(0, 0, 0, 0.15), -16px 16px 14px rgba(0, 0, 0, 0.1), -24px 24px 16px rgba(0, 0, 0, 0.075), -32px 32px 24px rgba(0, 0, 0, 0.05)';
    const height = 'calc(100vh - 116px)';

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);

    const filteredDashboards = useMemo(() =>
        filterDashboardList(search || '')
            .filter(dashboard => dashboard.source === localWorkspace?.name)
            .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
    , [filterDashboardList, localWorkspace, search]);

    const hasSharedByMe = useMemo(() => !!filterDashboardList(search || '', localWorkspace?.id).length
    , [filterDashboardList, localWorkspace, search]);

    const handleSave = useCallback((investmentThesis: InvestmentThesisType) => {
        return new Promise<boolean>((resolve) => {
            if (!!localWorkspace) {
                updateInvestmentThesisFunc({
                    id: localWorkspace.id,
                    group: getURLFrom()?.originGroup || userGroup,
                    investmentThesis: investmentThesis,
                }).then((updatedWorkspace?: Workspace) => {
                    setWorkspaces(prev => prev.map(ws =>
                        ws.id === localWorkspace.id ? {...updatedWorkspace!} : ws
                    ));
                    setWorkspace({...updatedWorkspace!});
                }).finally(() =>  resolve(true));
            } else {
                resolve(false);
            }
        });
    // eslint-disable-next-line
    }, [getURLFrom, localWorkspace, userGroup]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            clearTimeout(timerRef.current);
            setDashboardArray(filteredDashboards);

            if (!hasSharedByMeCheck.current && hasSharedByMe && !filteredDashboards.length) {
                hasSharedByMeCheck.current = true;
                setSelected(SharedBy.Me);
            }
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    // eslint-disable-next-line
    }, [filteredDashboards]);

    useEffect(() => {
        if (isPublicView)
            setSelected(SharedBy.Me);
    }, [isPublicView]);

    if (!localWorkspace)
        return (<></>);

    return (<>
        {!dashboardsLoaded ? (
            <FallbackLoading height={`calc(100vh - 250px)`} />
        ) : (
            <Stack direction="row" alignItems="flex-start" width="100%" height="100%">
                <Stack direction="column" className={classes.panel} alignItems="stretch" justifyContent="flex-start"
                    sx={isPublicView ? { maxWidth: 'unset !important'} : {}}>
                    <Box className={classNames(expanded && classes.expanded)}>
                        <TabContext value={selected}>
                            {!isPublicView && (<>
                                <Stack direction="row" justifyContent="space-between" width="100%">
                                    <Stack direction="row" alignItems="center" justifyContent="flex-start" ml={2}>
                                        <Typography className={classes.menuLabel} fontSize="1.1rem !important">
                                            {'Shared by: '}
                                        </Typography>
                                        <FormControl className={classes.selectorForm} variant="outlined">
                                            <Select value={selected} onChange={(e) => setSelected(e.target.value as SharedBy)}
                                                renderValue={(selected: string) => (
                                                    <Typography className={classes.menuSelected} fontSize="1.1rem !important">
                                                        {(selected === SharedBy.DealOwner) ? localWorkspace.name : SharedBy.Me}
                                                    </Typography>
                                                )}>
                                                <MenuItem className={classes.menuItem} value={SharedBy.DealOwner}>
                                                    <Typography className={classNames(classes.menuLabel, (selected === SharedBy.DealOwner) && classes.menuSelected)}>
                                                        {localWorkspace.name}
                                                    </Typography>
                                                </MenuItem>
                                                <MenuItem className={classes.menuItem} value={SharedBy.Me}>
                                                    <Typography className={classNames(classes.menuLabel, (selected === SharedBy.Me) && classes.menuSelected)}>
                                                        {SharedBy.Me}
                                                    </Typography>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </Stack>
                                <Divider sx={{ width: '100%' }} />
                            </>)}
                            {!isPublicView && (
                                <TabPanel className={classes.tabPanel} value={SharedBy.DealOwner}
                                    sx={!expanded ? {maxWidth: '100% !important'}: {}}>
                                    <SharedByDealOwner
                                        workspace={localWorkspace || undefined}
                                        dashboards={dashboardArray}
                                        loading={!dashboardsLoaded} />
                                </TabPanel>
                            )}
                            <TabPanel className={classes.tabPanel} value={SharedBy.Me}
                                sx={{ maxWidth: !expanded ? '100% !important' : 'calc(100vw - 94px - 550px)',
                                      paddingBottom: '40px !important'}}>
                                <SharedByMe workspace={workspace} search={search} />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Stack>
                {expanded ? (<>
                    <ClickAwayListener onClickAway={(e) => {e.stopPropagation(); setExpanded(false);}}>
                        <Stack className={classes.preferences} width="100%"
                            height={height} p={1} sx={{ boxShadow, transform: 'translateY(-10px)' }}>
                            <Preferences workspace={localWorkspace} onSave={handleSave} onCollapse={() => setExpanded(false)} />
                        </Stack>
                    </ClickAwayListener>
                </>) : !isPublicView && (<>
                    <Divider orientation="vertical" sx={{ height, marginTop: '-8px !important' }} />
                    <Stack direction="column" className={classes.preferences} spacing={3} alignItems="center" mt={1} px={3}
                        sx={{ width: 'fit-content', height: '100%', cursor: 'pointer'}}
                        onClick={(e) => {
                            e.stopPropagation();
                            setExpanded(true);
                        }}>
                        <Tooltip
                            placement="right-start"
                            title={<Typography fontSize="0.95rem" fontWeight={600}>{'Profile'}</Typography>}
                            componentsProps={{tooltip:{sx:{
                                paddingLeft: 2, paddingRight: 2,
                                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            }}}}>
                            <IconButton onClick={(e) => {
                                e.stopPropagation();
                                setExpanded(true);
                            }}> <ArrowBackIosIcon /> </IconButton>
                        </Tooltip>
                        <PersonOutlineOutlinedIcon sx={{ width: 32, height: 32, fill: 'rgba(0, 0, 0, 0.5)' }} />
                    </Stack>
                </>)}
            </Stack>
        )}
    </>);
}

export default InvestmentThesis;
