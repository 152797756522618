import React, { useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import classnames from "classnames";
import InvestorsMultiAction from "../multi-action/InvestorsMultiAction";
import theme from "../../../theme";
import SyncIcon from '@mui/icons-material/Sync';
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

const useStyles = makeStyles((theme) => ({
    row: {
        minWidth: '100%',
        width: 'fit-content',
        height: 'fit-content',
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        background: `#fff`,
    },
    header: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['900'],
    },
    checkbox: {
        minWidth: 24,
        maxWidth: 24,
    },
    cell: {
        minWidth: 180,
        maxWidth: 180,
        minHeight: 60,
        maxHeight: 60,
        padding: '0 10px',
    },
    narrowCell: {
        minWidth: 150,
        maxWidth: 150,
    },
    narrowerCell: {
        minWidth: 50,
        maxWidth: 50,
    },
    customNarrowCell: {
        minWidth: 20,
        maxWidth: 20,
    },
    wideCell: {
        minWidth: 280,
        maxWidth: 280,
    },
    widerCell: {
        minWidth: 380,
        maxWidth: 380,
    },
    fullWidthCell: {
        minWidth: 0,
        maxWidth: 'unset !important',
        width: '100%',
    },
    hiddenCell: {
        display: 'none',
    },
    endCell: {
        minWidth: 4,
        maxWidth: 4,
        padding: 'unset',
    },
    commonButton: {
        height: 36,
        borderRadius: 32,
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
    },
    icon: {
        width: 20,
        height: 20,
    }
}));

export const TableHeaders = ['Name', 'Email', '', 'Sector', 'Stage', 'Geography', ''];
export const FullTableHeaders = ['Name', 'Fit score', 'Email', '', 'Sector', 'Stage', 'Geography', ''];

const WorkspacesTableHeader: React.FC<{
    columns: string[],
    multiAction?: boolean,
    onRefreshScores?: () => void,
}> = ({ columns, multiAction, onRefreshScores }) => {
    const classes = useStyles();
    const multiActionRef = useRef<any>(null);

    const showColumn = (column: string) => columns.join(';').includes(column);

    return (<>
        <Stack direction="row" className={classes.row} alignItems="flex-start" justifyContent="flex-start" ref={multiActionRef}>
            <Stack className={classes.checkbox} />
            {FullTableHeaders.map((header, i, self) => showColumn(header) && (
                <Stack direction="column" className={classnames(classes.cell,
                    (i === 3) && classes.customNarrowCell,
                    (i === 4) && classes.wideCell,
                    (i === 5) && classes.wideCell,
                    (i === 6) && classes.wideCell,
                    (i === self.length - 1) && classes.fullWidthCell)}
                    alignItems={(i === self.length - 1) ? 'flex-end' : 'flex-start'}
                    justifyContent="center" key={'workspaces-table-header-83-' + i}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                        <Typography className={classes.header}>{header}</Typography>
                        {(['Fit score'].includes(header)) && (<>
                            <ArrowDropDownIcon sx={{ fill: theme.palette.primary.main, transform: 'scale(1.5, 2.5)'}} />
                        </>)}
                        {!!onRefreshScores && (header === 'Fit score') && (<>
                            <Tooltip placement="top-start" title={
                                <Typography component="span" fontSize="0.95rem" fontWeight={500}>
                                    {`Calculate fit scores for each company based on each workspace's preferences.`}
                                </Typography>}
                                componentsProps={{tooltip:{sx:{
                                    display: 'flex', alignItems: 'center', borderRadius: '12px', transform: 'translate(-90px) !important',
                                    backgroundColor: 'rgba(150, 150, 150, 0.95)', width: '210px', height: '110px',  padding: 2,
                                }}}}>
                                <IconButton onClick={() => onRefreshScores?.()} sx={{ padding: 'unset' }} disableRipple>
                                    <SyncIcon sx={{ fill: theme.palette.primary.main, transform: 'scale(1.05, 1.05)', translate: '-5px 1px' }} />
                                </IconButton>
                            </Tooltip>
                        </>)}
                    </Stack>
                </Stack>
            ))}
            <Stack className={classnames(classes.cell, classes.endCell)} />
        </Stack>
        {multiAction && (<InvestorsMultiAction ref={multiActionRef} />)}
    </>);
}

export default WorkspacesTableHeader;