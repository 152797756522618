import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import { onDocSendIngestionCreation, onUnassignedContent } from '../graphql/subscriptions';
import { getUserEmail } from '../helpers/authUser';
import useEmailDealsExtraction, { EmailDeal } from '../hooks/useEmailDealsExtraction';
import useFileUploader from '../hooks/useFileUploader';
import useStaticDashboard from '../hooks/useStaticDashboard';
import { assignContentToDashboardFunc, createDashboardFunc, createDocSendIngestionFunc, deleteUnassignedContentFunc, getUnassignedContentItemsFunc } from '../lib/helper';
import { User } from '../types/auth';
import { AssignUnassignedContentInput, Dashboard, DocSendIngestion, UnassignedContent, UnassignedContentType } from '../types/files';
import { AuthContext } from './AuthContext';
import { DashboardsContext } from './DashboardsContext';
import { GroupSettingsContext } from './GroupSettingsContext';

export type UnassignedContentData = {
  key: string,
  content: UnassignedContent,
  deals: EmailDeal[],
  exists: boolean[],
  createdAt: string,
};

type InboxContextProps = {
  contentsData: UnassignedContentData[];
  saveEmailDeals: (content: UnassignedContent, dashboard?: Dashboard, deals?: EmailDeal[]) => Promise<any>;
  ingestDocsend: (unassignedContentId: string, docsend: DocSendIngestion, password: string) => Promise<any>;
  removeContent: (unassignedContentId: string) => Promise<any>;
};

export const InboxContext = createContext<InboxContextProps>({
  contentsData: [],
  saveEmailDeals: () => Promise.resolve(),
  ingestDocsend: () => Promise.resolve(),
  removeContent: () => Promise.resolve(),
});

const InboxProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const {user, userGroup} = useContext(AuthContext);
  const { externalSync } = useContext(GroupSettingsContext);
  const { dashboards } = useContext(DashboardsContext);
  const { extractEmailDeals } = useEmailDealsExtraction();
  const { saveWebQueryAnswer } = useStaticDashboard();
  const { docsendIngestion } = useFileUploader({});

  const [incomingContents, setIncomingContents] = useState<UnassignedContent[]>([]);
  const [outgoingContents, setOutgoingContents] = useState<UnassignedContent[]>([]);
  const [contentsData, setContentsData] = useState<UnassignedContentData[]>([]);
  // eslint-disable-next-line
  const [ingestableDeals, setIngestableDeals] = useState<{content: UnassignedContent, deals: EmailDeal[]}[]>([]);

  const unassignedContentSubscriptionRef = useRef<any>(null);
  const onDocSendIngestionCreationRef = useRef<any>(null);

  const filteredContents = useMemo(() => incomingContents.filter(content => {
    if (content?.type === UnassignedContentType.DOCSEND) {
      const parsed = JSON.parse(content?.body || '{}') as DocSendIngestion;
      const lookUpDashboard = dashboards.find(dashboard => parsed?.existingDashboardId ?
        dashboard.id === parsed?.existingDashboardId : dashboard.title === parsed?.newDashboardTitle);

      return !!lookUpDashboard;
    }

    return true;
  }), [dashboards, incomingContents]);

  const updateSubscribedContents = useCallback(async (
    incomingContents: UnassignedContent[],
    outgoingContents: UnassignedContent[],
    updatedContent?: UnassignedContent|null
  ) => {
    if (!updatedContent?.id)
      return;

    const lookUpIncomingContent = incomingContents.find(content => content.id === updatedContent.id);
    const lookUpOutgoingContent = outgoingContents.find(content => content.id === updatedContent.id);

    if (!lookUpOutgoingContent && !lookUpIncomingContent)
      setIncomingContents(prev => [updatedContent, ...prev]);
  }, []);

  const subscribeUnassignedContents = useCallback(async (
    incomingContents: UnassignedContent[],
    outgoingContents: UnassignedContent[]
  ) => {
    if (unassignedContentSubscriptionRef.current)
      unassignedContentSubscriptionRef.current.unsubscribe();

    if (userGroup) {
      unassignedContentSubscriptionRef.current = await (API.graphql(
        graphqlOperation(onUnassignedContent, { group: userGroup })) as any)
        .subscribe({ next: ({ value }: any) => updateSubscribedContents(
          incomingContents, outgoingContents, value?.data?.onUnassignedContent
        )});
    }
  }, [userGroup, updateSubscribedContents]);

  const removedSubscribedContents = useCallback(async (updatedContent?: UnassignedContent|null) => {
    if (!updatedContent?.id)
      return;

    setIncomingContents(prev => [...prev].filter(content => content.id !== updatedContent!.id));
  }, []);

  const subscribeDocsendIngestionCreation = useCallback(async () => {
    if (onDocSendIngestionCreationRef.current)
      onDocSendIngestionCreationRef.current.unsubscribe();

    if (userGroup) {
      onDocSendIngestionCreationRef.current = await (API.graphql(
        graphqlOperation(onDocSendIngestionCreation, { group: userGroup })) as any)
          .subscribe({ next: ({ value }: any) => removedSubscribedContents(
            value?.data?.onDocSendIngestionCreation
        )});
    }
  }, [userGroup, removedSubscribedContents]);

  const moveContent = useCallback((contentId: string) => {
    const lookUpContent = incomingContents.find(content => content.id === contentId);

    if (!!lookUpContent) {
      setOutgoingContents(prev => [lookUpContent, ...prev]);
      setIncomingContents(prev => [...prev]
        .filter(content => content.id !== lookUpContent.id));
    }
  }, [incomingContents]);

  const handleAssignContent = useCallback(async (unassignedContentId: string, dashboards: AssignUnassignedContentInput[]) => {
    moveContent(unassignedContentId);
    await assignContentToDashboardFunc({unassignedContentId, dashboards});
  }, [moveContent]);

  const ingestDocsend = useCallback(async (unassignedContentId: string, docsend: DocSendIngestion, password: string) => {
    moveContent(unassignedContentId);

    return await createDocSendIngestionFunc({ ...docsend,
      email: getUserEmail(user as User),
      group: userGroup,
      password,
    });
  }, [moveContent, user, userGroup]);

  const removeContent = useCallback(async (unassignedContentId: string) => {
    moveContent(unassignedContentId);

    return await deleteUnassignedContentFunc({id: unassignedContentId, group: userGroup});
  }, [moveContent, userGroup]);

  const getContentsData = useCallback(() => new Promise<UnassignedContentData[]>(async (resolve) => {
    const contents: UnassignedContentData[] = [];

    for (const content of filteredContents) {
      if (!outgoingContents.find(out => out.id === content.id)) {
        if (content.type === UnassignedContentType.FULL_EMAIL) {
          let deals: EmailDeal[] = await extractEmailDeals(content);
          let exists: boolean[] = [];

          if (!!deals.length)
            exists = deals.map(deal => !!dashboards.find(dashboard => dashboard.title.toLowerCase() === deal.name.toLowerCase()));

          contents.push({ key: uuidv4(), createdAt: String(content.createdAt || ''), content, deals, exists });
        }
      }
    }

    setContentsData(contents.sort((ctxA, ctxB) => moment(ctxB.content.createdAt).diff(moment(ctxA.content.createdAt))));
    resolve(contents);
  // eslint-disable-next-line
  }), [filteredContents, outgoingContents, dashboards]);

  const ingestEmailDeal = useCallback(async (deal: EmailDeal, content: UnassignedContent) => {
    const isExternalLink = !!deal.deck?.name && !deal.deck?.name?.toLowerCase()?.includes('.pdf');
    const newDashboard = {
      createdAt: (new Date()).toISOString(),
      updatedAt: (new Date()).toISOString(),
      userId: getUserEmail(user!),
      email: getUserEmail(user!),
      group: userGroup!,
      project: userGroup!,
      id: uuidv4(),
      title: deal.name,
      documents: [],
      selections: [],
      screenshots: [],
      notes: [],
      lastUpdatedBy: '',
      shouldRefresh: false,
      externalLinks: isExternalLink ? [deal.deck?.name] : null,
      shouldSyncExternally: externalSync,
      refreshData: null,
      summary: '',
      status: deal.status,
      investmentStage: deal.stage,
      source: content.source,
    } as Dashboard;

    if (!dashboards.find(dashboard => dashboard.title.trim() === deal.name.trim())) {
      return await new Promise<{dashboardId: string, deal: EmailDeal}|null>((resolve) => {
        createDashboardFunc(newDashboard).then(() => {
          console.log(`CREATED: ${newDashboard.title}`);
          Promise.all([
            !!deal.website ? saveWebQueryAnswer(newDashboard, deal.website) : Promise.resolve(true),
            docsendIngestion(newDashboard, deal.deck?.name),
          ]).then(() => {
            resolve({dashboardId: newDashboard.id, deal});
          }).catch(() => resolve(null));
        }).catch(() => resolve(null));
      });
    }

    return null;
  }, [user, userGroup, externalSync, dashboards, saveWebQueryAnswer, docsendIngestion]);

  const saveEmailDeals = useCallback(async (content: UnassignedContent, dashboard?: Dashboard, deals?: EmailDeal[]) => {
    if (!dashboard && !!deals?.length) {
      const ingestPromises: Promise<any>[] = [];

      deals?.forEach(deal => ingestPromises.push(ingestEmailDeal(deal, content)));

      const assignables: ({dashboardId: string, deal: EmailDeal}|null)[] = await Promise.all(ingestPromises);

      if (!!assignables.length) {
        const assignableContents: AssignUnassignedContentInput[] = [];

        assignables.forEach((assignable, i) => {
          if (!!assignable) {
            assignableContents.push({
              dashboardId: assignable.dashboardId,
              attachments: !!deals?.[i]?.deck?.name?.toLowerCase()?.includes('.pdf') ? [deals?.[i]?.deck?.name ?? null] : [],
            });
          }
        });

        await handleAssignContent(content.id!, assignableContents);
      }
    } else {
      await handleAssignContent(content.id!, [{dashboardId: dashboard?.id!, attachments: content.attachments}]);
    }
    setContentsData(prev => [...prev].filter(ctx => ctx.content.id !== content.id));
  }, [handleAssignContent, ingestEmailDeal]);

  useEffect(() => {
    if (userGroup) {
      getUnassignedContentItemsFunc({group: userGroup})
        .then((contents: UnassignedContent[]|undefined) =>
          setIncomingContents(contents ?? []));
    }
  }, [userGroup]);

  useEffect(() => {
    subscribeUnassignedContents(incomingContents, outgoingContents);
    subscribeDocsendIngestionCreation();

    return () => {
      unassignedContentSubscriptionRef.current?.unsubscribe();
      onDocSendIngestionCreationRef.current?.unsubscribe();
    }
    // eslint-disable-next-line
  }, [incomingContents, subscribeUnassignedContents]);

  useEffect(() => {
    getContentsData().then(contents => setIngestableDeals(contents
      .flatMap(({content, deals, exists}) =>
        ({content, deals: deals.filter((deal, i) => !exists[i] && !!deal.website)}))
      .filter(({deals}) => !!deals.length)
    ));
    // eslint-disable-next-line
  }, [getContentsData]);

  const contextValue = useMemo(() => ({
    contentsData,
    saveEmailDeals,
    ingestDocsend,
    removeContent,
  }), [
    contentsData,
    saveEmailDeals,
    ingestDocsend,
    removeContent,
  ]);

  return (
    <InboxContext.Provider value={contextValue}>
      {children}
    </InboxContext.Provider>
  );
};

export default InboxProvider;
