import { Divider, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import React, { useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { DashboardContext } from "../../../contexts/DashboardContext";
import useRoute from "../../../hooks/useRoute";
import { CompanyFile } from "../../../types/files";
import hideDisplay from "../../../utils/hideDisplay";
import DashboardExport from "../../atoms/dashboards/DashboardExport";
import DashboardFileUploader from "../../atoms/dashboards/DashboardFileUploader";
import DashboardShare from "../../atoms/dashboards/DashboardShare";
import ZapierSync from "../../atoms/ZapierSync";
import { getFileIcon } from "../../folders/utils/files";
import DashboardTabs, { ViewModes } from "../../molecules/dashboard-header/DashboardTabs";
import { DashboardTitle } from "../../molecules/dashboard-header/DashboardTitle";
import CompanyMultiAction from "../../molecules/multi-action/CompanyMultiAction";

const useStyles = makeStyles(() => ({
    titleContainer: {
        width: '100%',
        height: 100,
        padding: 8,
        opacity: 1,
        transition: 'height .3s ease-in-out, opacity .6s ease-in-out',
    },
    hideContainer: {
        opacity: 0,
        height: 0,
        overflow: 'hidden',
    },
    title: {
        width: '100%',
        color: 'black',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    icon: {
        width: 24,
        heigh: 24,
    },
    fileNameContainer: {
        width: '100%',
        height: '100%',
        marginBottom: 8,
    },
    fileName: {
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    tabActions: {
        height: '100%',
        width: '100%',
    },
}));

const DashboardHeader: React.FC<{
    viewMode: string,
    selectedFile?: CompanyFile | null,
    onSetViewMode: (mode: string) => void,
}> = ({ viewMode, selectedFile, onSetViewMode}) => {
    const classes = useStyles();
    const params: {[key: string]: string} = useParams();
    const { dashboard, isPublicView } = useContext(DashboardContext);
    const {redirectToDashboard} = useRoute();

    const handleSelectMode = useCallback((mode: string) => {
        const { dashboardId } = params;

        onSetViewMode(mode);
        if (isPublicView && (mode === ViewModes.Files) && !!selectedFile)
            redirectToDashboard(dashboardId, [ViewModes.Files, selectedFile.id].join('/'));
        else
            redirectToDashboard(dashboardId, mode !== ViewModes.Overview ? mode.toLowerCase() : '');
    }, [isPublicView, onSetViewMode, params, redirectToDashboard, selectedFile]);

    if (!dashboard)
        return (<></>);

    return (<>
        <Stack className={classnames(classes.titleContainer, (viewMode === ViewModes.Files) && classes.hideContainer)}>
            {!!dashboard && (viewMode !== ViewModes.Files) && (
                <DashboardTitle readonly={isPublicView} />
            )}
        </Stack>
        <Stack direction="row" alignItems="center" width="100%" height={40}>
            {viewMode === ViewModes.Files && (<>
                <Typography className={classes.title}>{dashboard?.title}</Typography>
                <Stack direction="row" className={classes.fileNameContainer} alignItems="center" justifyContent="center">
                    <Stack direction="row" spacing={2} alignItems="center">
                        {selectedFile && getFileIcon(selectedFile?.name, classes.icon)}
                        <Typography className={classes.fileName}>{selectedFile?.name}</Typography>
                    </Stack>
                </Stack>
            </>)}
            <Stack direction="row" className={classes.tabActions} spacing={2} alignItems="center"
                justifyContent={(viewMode === ViewModes.Files) ? 'right' : 'space-between'}>
                <DashboardTabs
                    selectedMode={viewMode}
                    isPublic={isPublicView}
                    hasDeck={!!selectedFile?.isDeck}
                    onSelectMode={handleSelectMode} />
                {!isPublicView && (viewMode === ViewModes.Overview) && (
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" pb={2}>
                        <CompanyMultiAction />
                        {/* <ShareDealButton dashboard={dashboard} /> */}
                    </Stack>
                )}
                {hideDisplay(!isPublicView && (
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                        <ZapierSync hidden={viewMode !== ViewModes.Overview} />
                        <DashboardExport hidden={viewMode !== ViewModes.Overview} />
                        <DashboardFileUploader hidden={![ViewModes.Overview, ViewModes.Files].includes(viewMode as ViewModes)}  />
                        <DashboardShare hidden={viewMode !== ViewModes.Overview} />
                    </Stack>
                ))}
            </Stack>
        </Stack>
        <Divider sx={{ width: '100%' }} />
    </>);
}

export default DashboardHeader;